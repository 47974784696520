import React, {useEffect, useState} from "react";
import axios from "axios";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Cookies from "universal-cookie";
import {GitHub} from "@mui/icons-material";

const cookies = new Cookies();

const Template2 = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [designation, setDesignation] = useState("");
    const [profilePic, setProfilePic] = useState("");

    const [personalDetails, setPersonalDetails] = useState([]);
    const [workExperience, setWorkExperience] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [skills, setSkills] = useState([]);
    const [certificates, setCertificates] = useState([]);

    const getPersonalDetails = () => {
        axios
            .get(
                `profile/personal`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setPersonalDetails(res.data);
                setFirstName(res.data[0].first_name);
                setLastName(res.data[0].last_name);
                setDesignation(res.data[0].designation);
                setProfilePic(res.data[0].profile_pic);
            })
            .catch((err) => {
                
            })
    }

    const getEducationData = () => {
        axios
            .get(
                `profile/education-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setEducationData(res.data);
            })
            .catch((err) => {
                
            })
    }

    const getWorkExperience = () => {
        axios
            .get(
                `profile/workExperience`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setWorkExperience(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getSkills = () => {
        axios
            .get(
                "/profile/skills",
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setSkills(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getCertificate = () => {
        axios
            .get(
                `profile/certificate`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setCertificates(res.data);
            })
            .catch((err) => {
                
            });
    }

    useEffect(() => {
        getPersonalDetails();
        getEducationData();
        getWorkExperience();
        getSkills();
        getCertificate();
    }, []);

    return (
        <div className="font-serif">
            <div className=" bg-[#203f56] text-white mb-5">
                <div className="flex px-8 py-4 space-x-5">
                    <div className="flex-grow space-y-4 py-4">
                        <div>
                            <p className="text-5xl">
                                {firstName} {lastName}
                            </p>
                            <p className="text-xl">
                                {designation}
                            </p>
                        </div>
                        <p className="text-justify text-sm break-words">
                            Your profile is one of the most important parts of your resume.
                            this section should give a concise summary of your career to date (5 sentences max).
                            this will be one of the first section your future employee/recruiter will see, and you
                            donr want to be the only one!
                            Your profile needs to capture their attention and get them to read your whole CV.
                            One final tip, it is usually easy to write this part last.
                        </p>
                    </div>
                    <img src={profilePic} className="h-28 w-28 mt-5 rounded-full" alt=""/>
                </div>

                {personalDetails.map((details) => (
                    <div key={details} className="bg-[#193041] px-5 py-3 text-white space-y-2">
                        <div className="flex">
                            <p className="w-1/2">
                                <MailIcon fontSize={"small"} className="mr-4"/>
                                {details.email}
                            </p>
                            <p className="w-1/2">
                                <CallIcon fontSize={"small"} className="mr-4"/>
                                {details.mobile_no}
                            </p>
                        </div>

                        <div className="flex">
                            <p className="w-1/2">
                                <LocationOnIcon fontSize={"small"} className="mr-4"/>
                                {details.city}, {details.country}
                            </p>
                            <p className="w-1/2">
                                <GitHub fontSize={"small"} className="mr-4"/>
                                {details.github_id}
                            </p>
                        </div>

                        <div className="flex flex-row">
                            <p>
                                <LinkedInIcon fontSize={"small"} className="mr-4"/>
                                {details.linkedin_id}
                            </p>

                        </div>
                    </div>
                ))}
            </div>

            <div className="px-8 space-y-5">
                <div>
                    <p className="underline underline-offset-8 mb-3 text-xl font-bold">
                        Areas of Expertise
                    </p>

                    <div className="flex flex-wrap break-words">
                        {skills.map((skillData) => (
                            <div key={skillData.id} className="bg-slate-500 text-white rounded-md m-2 px-3 py-1">
                                {skillData.skills}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="underline underline-offset-8 mb-3 text-xl font-bold">
                        Education
                    </p>

                    {educationData.map((education) => (
                        <div key={education.id}>
                            <p>
                                {education.course}
                            </p>
                            <p>
                                {education.university_name}, {education.pass_year}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="space-y-3">
                    <p className="underline underline-offset-8 text-xl font-bold">
                        Professional Experience
                    </p>
                    {workExperience.map((workEx) => (
                        <div key={workEx.id}>
                            <p className="text-lg font-semibold">
                                {workEx.position}
                            </p>
                            <div className="flex">
                                <div className="flex-grow">
                                    <p>
                                        {workEx.company_name}
                                    </p>
                                </div>
                                <p>
                                    {workEx.start_date} - {workEx.job_status === "On-Going" ? workEx.job_status : workEx.end_date}
                                </p>
                            </div>
                            <p>
                                {workEx.description}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="space-y-3">
                    <p className="underline underline-offset-8 mb-3 text-xl font-bold">
                        Certificates
                    </p>

                    {certificates.map((certiData) => (
                        <div key={certiData}>
                            <p className="font-semibold break-words text-lg">
                                {certiData.degree_name}
                            </p>
                            <p>
                                {certiData.university_name}
                            </p>
                            <p>
                                {certiData.start_year} - {certiData.end_year}
                            </p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export default Template2;