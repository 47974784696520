import { Box, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { saveServeyAnswer } from "../../controller/serveyController";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { HTTPStatus } from "../../utils/helperObj";
/**
 * @typedef {object} Props
 * @property {import("../../model/ServeyModel").ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */
export default function QuestionTextField({ question }) {
    const componentMounted = useRef(false);
    const [textValue, setTextValue] = useState(question.answer !== null ? question.answer : "");
    const navigate = useNavigate();
    const timeoutRef = useRef(null);

    const handleAnswer = useCallback(
        async (textValue) => {

            const res = await saveServeyAnswer(
                question.id,
                textValue,
                question.questionType
            );

            if (res.statusCode !== HTTPStatus.CREATED) {
                handleControllerResultException(res, navigate);
            }

        },
        [navigate, question.id, question.questionType]
    );

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (componentMounted.current) {
            timeoutRef.current = setTimeout(() => {
                handleAnswer(textValue);
            }, "700");
        } else {
            componentMounted.current = true;
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [textValue, handleAnswer]);

    return (
        <Box>
            <TextField
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                multiline
                rows={5}
                fullWidth
            />
        </Box>
    );
}
