import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import React, { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import CertificateAdd from "./Certificates/CertificateAdd";
import CertificateUpdate from "./Certificates/CertificateUpdate";
import Certificates from "./Certificates/Certificates";
import Document from "./Documents/Document";
import Education from "./Education/Education";
import EducationAdd from "./Education/EducationAdd";
import EducationUpdate from "./Education/EducationUpdate";
import "./ProfessionalDetails.scss";
import Resume from "./Resume/Resume";
import Skills from "./Skills/Skills";
import SkillsEdit from "./Skills/SkillsEdit";
import WorkExperienceUpdate from "./Work Experience/WorKExperienceUpdate";
import WorkExperience from "./Work Experience/WorkExperience";
import WorkExperienceAdd from "./Work Experience/WorkExperienceAdd";


const ProfessionalDetails = () => {
    const [variable, setVariable] = useState("");
    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="professional_main">
            <div>
                <TabsUnstyled defaultValue={0} className="mx-1 mt-2 md:mx-2">

                    <TabsListUnstyled
                        className="bg-white font-bold rounded-lg flex overflow-auto items-center text-neutral-400 content-between min-w-[220px]">
                        <div className="py-1.5 ml-1 px-0.5 md:px-2 text-[10px] md:text-base md:ml-1.5">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer
                                    py-1 px-1
                                    md:py-2 md:px-5 
                                    lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/work-experience" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/profile/professional-details/work-experience/add-more" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/profile/professional-details/work-experience/update" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                                onClick={() => {
                                    navigate("work-experience")
                                }}
                            >
                                Work Experience
                            </p>
                        </div>

                        <div className="py-1.5 px-0.5 md:px-2 text-[10px] md:text-base">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer 
                                py-1 px-2
                                md:py-2 md:px-5
                                lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/skills" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/profile/professional-details//skills-edit" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                                onClick={() => {
                                    navigate("skills")
                                }}
                            >
                                Skills
                            </p>
                        </div>

                        <div className="py-1.5 px-0.5 md:px-2 text-[10px] md:text-base">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer
                                py-1 px-2
                                md:py-2 md:px-5
                                lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/education" ? "bg-[#F1F1F1] text-sky-700" : ""} 
                                ${window.location.pathname === "/profile/professional-details/education/add" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/profile/professional-details/education/update" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                                onClick={() => {
                                    navigate("education")
                                }}
                            >
                                Education
                            </p>
                        </div>

                        <div className="py-1.5 px-0.5 md:px-2 text-[10px] md:text-base">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer
                                py-1 px-2
                                md:py-2 md:px-5
                                lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/certificate" ? "bg-[#F1F1F1] text-sky-700" : ""} 
                                ${window.location.pathname === "/profile/professional-details/certificate/add" ? "bg-[#F1F1F1] text-sky-700" : ""}
                                ${window.location.pathname === "/profile/professional-details/certificate/update" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                                onClick={() => {
                                    navigate("certificate")
                                }}
                            >
                                Certificates
                            </p>
                        </div>

                        <div className="py-1.5 px-0.5 md:px-2 text-[10px] md:text-base">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer
                                py-1 px-2
                                md:py-2 md:px-5
                                lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/upload-resume" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                                onClick={() => {
                                    navigate("upload-resume")
                                }}
                            >
                                Upload Resume
                            </p>
                        </div>

                        <div className="py-1.5 px-0.5 md:px-2 text-[10px] md:text-base">
                            <p
                                className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] cursor-pointer
                                py-1 px-2
                                md:py-2 md:px-5
                                lg:py-2 lg:px-5
                                ${window.location.pathname === "/profile/professional-details/upload-documents" ? "bg-[#F1F1F1] text-sky-700" : ""} `}
                                onClick={() => {
                                    navigate("upload-documents")
                                }}
                            >
                                Upload Documents
                            </p>
                        </div>
                    </TabsListUnstyled>

                    {/*<TabPanelUnstyled value={0} onClick={(e) => setVariable(e.currentTarget.id)}>*/}

                    {/*</TabPanelUnstyled>*/}

                    {/*<TabPanelUnstyled value={1} onClick={(e) => setVariable(e.currentTarget.id)}>*/}

                    {/*</TabPanelUnstyled>*/}

                    {/*<TabPanelUnstyled value={2} onClick={(e) => setVariable(e.currentTarget.id)}>*/}
                    {/*</TabPanelUnstyled>*/}

                    <Routes>
                        <Route exact path="/" element={<Navigate replace to="work-experience" />} />
                        <Route path="work-experience" element={<WorkExperience />} />
                        <Route path="work-experience/add-more" element={<WorkExperienceAdd />} />
                        <Route path="work-experience/update" element={<WorkExperienceUpdate />} />

                        <Route path="skills" element={<Skills />} />
                        <Route path="skills-edit" element={<SkillsEdit />} />

                        <Route path="education" element={<Education />} />
                        <Route path="education/add" element={<EducationAdd />} />
                        <Route path="education/update" element={<EducationUpdate />} />

                        <Route path="certificate" element={<Certificates />} />
                        <Route path="certificate/add" element={<CertificateAdd />} />
                        <Route path="certificate/update" element={<CertificateUpdate />} />

                        <Route path="upload-resume" element={<Resume />} />
                        <Route path="upload-documents" element={<Document />} />
                    </Routes>
                </TabsUnstyled>
            </div>

        </div>
    );
};

export default ProfessionalDetails;
