import React, { useEffect, useState } from "react";
// import Header from '../NavBar-Sidebar/Header'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectHeader } from "../../features/HeaderSlice";
// import Spinner from "../Meeting/Spinner";
import Button from "@mui/material/Button";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

// import {MdArrowBackIos} from "react-icons/md";
import { Box, Pagination } from "@mui/material";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { base_url } from "../../components/consts";
import NoMessage from "../../images/NoMessage.png";

const cookies = new Cookies();

const Message = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [mHeader, setMHeader] = useState("");
  const [mMessage, setMMessage] = useState("");
  const [mID, setMID] = useState(0);
  const [status, setStatus] = useState("Received");
  const [toDate, setToDate] = useState("3000-1-1");
  const [fromDate, setFromDate] = useState("1900-1-1");
  const [active, setActive] = useState("All");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const from = new Date(fromDate);
  const to = new Date(toDate);

  const currentState = useSelector(selectHeader);

  async function getData(page = 1) {
    await axios
      .get(`profile/message?message_type=${status}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params: {
          page,
        },
      })
      .then((resp) => {
        setData(resp.data.results);
        setTotalPages(Math.ceil(resp.data.count / 10));
      })
      .catch((err) => { });
  }

  async function getMessage(id) {
    await axios
      .get(`profile/message/${id}?message_type=${status}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setMHeader(resp.data.header);
        setMMessage(resp.data.message);
        setMID(resp.data.id);
        setShowMessageModal(true);
      })
      .catch((err) => { });
  }

  const deleteMessage = (id) => {
    axios
      .get(`${base_url}/profile/message/delete/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        window.location.reload();
        toast.success("Successfully Deleted message", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        setShowMessageModal(false);
      })
      .catch((err) => {

        toast.error("Error Deleting Message", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  useEffect(() => {
    if (cookies.get("token")) {
      getData();
    } else {
      navigate("/");
    }
  }, [toDate, fromDate, status, navigate]);

  const all = () => {
    setActive("All");
    setStatus("Received");
  };
  const unRead = () => {
    setActive("un-read");
    setStatus("Unread");
  };
  const sent = () => {
    setActive("sent");
    setStatus("Sent");
  };

  return (
    <div className="bg-default-background h-screen overflow-auto">
      <Header />
      <Sidebar />

      <div className="bg-white lg:flex h-48 md:h-40 lg:h-12 xl:h-12 justify-between mt-16 mx-4" >
        <div className="bg-white space-x-1 flex lg:space-x-4 text-base md:text-lg xl:text-xl font-semibold p-1 text-gray-600">
          {active === "All" ? (
            <div
              onClick={all}
              className="py-1 px-4 rounded-lg bg-[#F1F1F1] text-sky-700"
            >
              All
            </div>
          ) : (
            <div
              onClick={all}
              className="py-1 px-4 rounded-lg hover:bg-[#F1F1F1] hover:text-sky-700"
            >
              All
            </div>
          )}

          {active === "un-read" ? (
            <div
              onClick={unRead}
              className="py-1 px-4 rounded-lg bg-[#F1F1F1] text-sky-700"
            >
              Un-read
            </div>
          ) : (
            <div
              onClick={unRead}
              className="py-1 px-4 rounded-lg hover:bg-[#F1F1F1] hover:text-sky-700"
            >
              Un-read
            </div>
          )}

          {active === "sent" ? (
            <div
              onClick={sent}
              className="py-1 px-4 rounded-lg bg-[#F1F1F1] text-sky-700"
            >
              Sent
            </div>
          ) : (
            <div
              onClick={sent}
              className="py-1 px-4 rounded-lg hover:bg-[#F1F1F1] hover:text-sky-700"
            >
              Sent
            </div>
          )}
        </div>

        <div className="md:flex mb-4 ml-4 md:ml-0 lg:mb-0 pt-2 space-y-3 md:space-y-0">
          <div className="flex">
            <span className="text-base text-slate-400 mr-2 mt-1 md:ml-10">
              <p>From :</p>
            </span>
            <input
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              className="w-44 px-6 md:mr-10 md:py-1 lg:py-4 lg:mr-4 xl:mr-10 bg-white border shadow-sm border-slate-300 placeholder-text-bold placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-2xl sm:text-sm focus:ring-1"
              type="date"
              placeholder=""
            />
          </div>
          <div className="flex">
            <span className="text-base mr-2 mt-1 text-slate-400 ">
              <p>To :</p>
            </span>
            <input
              onChange={(e) => {
                setToDate(e.target.value);
              }}
              className="w-44 px-6 md:mr-10 md:py-1 lg:py-4 lg:mr-4 xl:mr-10 bg-white border shadow-sm border-slate-300 placeholder-text-bold placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-2xl sm:text-sm focus:ring-1"
              type="date"
              placeholder=""
            />
          </div>
        </div>
        <div className="mb-5 lg:mb-0 xl:mb-0 lg:mt-1 ml-4 md:ml-5 lg:ml-0">
          <div className="mt-1 mr-2  md:mr-4">
            <Link to="/send-message">
              <Button className="w-auto px-5" size="small" variant="contained">
                Send Message
              </Button>
            </Link>
          </div>
        </div>
      </div>

      {data.length ? (
        <div className="pt-4 px-4 md:px-10 w-full ">
          <div className=" ease-in  duration-300  ml-0 ">
            <div className="overflow-auto  rounded-lg shadow  mb-12">
              <table className="w-full">
                <thead className="bg-slate-200 border-b-2 border-gray-200">
                  <tr className="text-blue-700 ">
                    <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                      {active === "sent" ? "Recipient" : "Sender"}
                    </th>
                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                      Message
                    </th>
                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                      Date
                    </th>
                    <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 text-center">
                  {data.map((user) => {

                    const date = new Date(user.updated_at.slice(0, 10));
                    const month = date.toLocaleString("default", {
                      month: "long",
                    });
                    if (from <= date && date <= to) {
                      return (
                        <tr
                          key={user.id}
                          className="bg-white hover:bg-slate-100"
                        >
                          <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                            {active === "sent" ? (
                              user.receiver_name
                            ) : (
                              <div>{user.sender_name}</div>
                            )}
                          </td>
                          <td className="p-3 MessageLine text-base text-gray-700 whitespace-nowrap">
                            <div className="font-semibold  text-lg">
                              {user.header}
                            </div>
                            {user.message.slice(0, 50)}
                          </td>
                          <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                            {date.getDate()}&nbsp;
                            {month.slice(0, 3)}&nbsp;
                            {date.getFullYear()}
                          </td>
                          <td className="p-3  text-base text-gray-700 whitespace-nowrap">
                            <div
                              className="bg-slate-100 px-2 py-2 rounded-lg text-blue-600 hover:bg-slate-300 hover:text-blue-700 cursor-pointer "
                              onClick={() => {
                                cookies.set("senderName", user.sender_name);
                                cookies.set("id", user.sender);
                                getMessage(user.id);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCenter"
                            >
                              View Message
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
            <Box
              sx={{ display: "flex", justifyContent: "center" }}
              marginBottom={"2rem"}
            >
              <Pagination
                count={totalPages}
                color="primary"
                shape="rounded"
                onChange={(e, page) => {
                  getData(page);
                }}
              />
            </Box>
          </div>
        </div>
      ) : (
        <EmptyState
          btn="Add Message"
          img={NoMessage}
          title="No Messages Found"
          message="No messages yet! Feel free to click on the plus button to compose a new message and reach out to a company or admin"
          btnLink="/send-message"
        />
      )}
      <Modal
        open={showMessageModal}
        onCancel={() => setShowMessageModal(false)}
        footer={false}
        centered
      >
        <div className="bg-white">
          <div className="px-4 py-2.5 grid grid-cols-5 mt-12">
            {/*<div>*/}
            {/*    <img className="w-10 h-10 rounded-full" src={cookies.get("image")} alt=""/>*/}

            {/*</div>*/}
            <div className="text-2xl break-words font-medium leading-normal text-gray-800 col-span-4">
              {mHeader}
            </div>
          </div>
          <div className="text-lg   relative p-4 border-t border-gray-200 mt-4">
            <div>{mMessage}</div>
          </div>
          {active === "sent" ? (
            ""
          ) : (
            <div className=" flex items-center justify-between p-4 border-t border-gray-200 rounded-b-md">
              <button
                onClick={() => deleteMessage(mID)}
                type="button"
                className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-3xl shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                Delete
              </button>
              <Link to="/send-reply">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-3xl shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  Reply
                </button>
              </Link>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Message;
