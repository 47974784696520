import { base_url } from "../components/consts";

export const API = {
    login: `${base_url}/auth/user/login`,
    signUp: `${base_url}/auth/user/register`,
    businessInfo: `${base_url}/profile/landing-page`,
    sendEmailVerificationOtp: `${base_url}/auth/no-auth-otp`,
    resendEmailVerificationOtp: `${base_url}/auth/user/resend/otp`,
    emailVerification: `${base_url}/auth/user/email/verification`,
    sendForgotPassOtpToEmail: `${base_url}/auth/user/forgot/password`,
    verifyForgotPassOtp: `${base_url}/auth/user/forgot/password/verify`,
    resetPassword: `${base_url}/auth/user/reset/password`,
    checkUserVerified: `${base_url}/auth/check-email-verification`,
    googleLogin: `${base_url}/auth/user/connect/google`,
    facebookLogin: `${base_url}/auth/user/connect/facebook`,
    linkedinLogin: `${base_url}/auth/user/connect/linkedin`,
    webinarList: `/profile/webinar-list`,
    webinarDetails: "/profile/webinar-detail",
    profileDetails: "/profile/personal",
    updateProfileDetails: "/profile/personal/update",
    addProfileDetails: "/profile/personal/add",
    brodcastMessage: {
        broadcastList: "/profile/broadcast-list"
    },
    servey: {
        getAssignment: "/profile/assignment-detail",
        saveAnswer: "/survey/answer-create",
        removeAnswer: "/survey/answer-delete"
    },
    job: {
        applyJob: "/profile/apply",
        jobList: "/profile/jobs"
    },
    message: {
        getMessage: "/profile/message",
        adminMessages: "/profile/adminemail",
        senMessage: "/profile/message/send",
        deleteMessage: "/profile/message/delete"
    },
    calender: {
        getMeetingDates: "/profile/next-meeting-dates"
    }
};

export const ObjType = {
    businessData: "BusinessData",
    httpResponse: "HttpResponse",
};

export const HTTPStatus = {
    SUCCESS: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
};
