import React from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Pie } from "react-chartjs-2";
import { CustomPie } from "./CustomPie";

// ChartJS.register(ArcElement, Tooltip, Legend);


const PieChart1 = ({ pieData, total }) => {


  return (
    <div>
      <div className="bg-white w-full h-[350px] rounded-lg text-xl font-bold">
        <div className="flex justify-between items-center bg-[#0856B6] p-2 rounded-t-lg">
          <h1 className="text-white">Total Applications</h1>
          <span className="text-white">{total}</span>
        </div>

        <div className="flex justify-center">
          <div className="flex justify-center h-[300px] min-w-[20rem] md:min-w-[22rem] items-center pb-4  pt-4">
            {total ? <CustomPie pieData={pieData} /> : <div className="">"No any Applications yet"</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart1;
