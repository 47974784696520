import { message } from "antd";
import axios from "axios";
import { useState } from "react";
import Cookies from "universal-cookie";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const cookies = new Cookies();

/**
 * @typedef {object} Props
 * @property {string} bookMarkUrl - The URL for bookmarking the card.
 * @property {string} unBookMarkUrl - The URL for unbookmarking the card.
 * @property {boolean} [defaultBookMark] - (optional) Indicates whether the card is bookmarked by default
 * @property {number | string} itemId - The ID of the item to be bookmarked.
 */

/**
 * @param {Props} props - The component's props object.
 * @returns {JSX.Element} - Renders a book mark
 *
 * Attributes: -
 * - bookMarkUrl - The URL for bookmarking the card.
 * - unBookMarkUrl - The URL for unbookmarking the card.
 * - defaultBookMark - (optional) Indicates whether the card is bookmarked by default
 * - itemId - The ID of the item to be bookmarked.
 */
export default function BookMark({
  bookMarkUrl,
  unBookMarkUrl,
  defaultBookMark = false,
  itemId,
}) {
  const [bookMark, setBookMark] = useState(defaultBookMark);

  /**
   *
   * @param {object} item
   * @returns void
   */
  const bookMarkFunc = async () => {
    try {
      await axios.post(
        bookMarkUrl,
        {
          position: itemId,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      );
      setBookMark(true);
      message.success("Bookmarked Successfully");
    } catch (err) {
      message.error(err.response.statusText);
    }
  };

  /**
   *
   * @param {object} item
   * @returns void
   */
  const unBookMarkFunc = async () => {
    try {
      await axios.delete(`${unBookMarkUrl}/${itemId}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      });

      message.warning("Bookmark is removed");
      setBookMark(false);
    } catch (err) {
      message.error(err.response.statusText);
    }
  };

  return (
    <div>
      {bookMark ? (
        <BookmarkIcon
          id={itemId}
          sx={{ color: "#0865B6", cursor: "pointer" }}
          onClick={() => {
            unBookMarkFunc();
          }}
        />
      ) : (
        <BookmarkBorderIcon
          id={itemId}
          sx={{ color: "#0865B6", cursor: "pointer" }}
          onClick={(e) => {
            bookMarkFunc();
          }}
        />
      )}
    </div>
  );
}
