import React, {useEffect, useState} from "react";
import axios from "axios";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {Button, Divider} from "@mui/material";
import SchoolIcon from '@mui/icons-material/School';


import Cookies from "universal-cookie";
const cookies = new Cookies();

const Template1 = () => {

    const [personalDetails, setPersonalDetails] = useState([]);
    const [workExperience, setWorkExperience] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [skills, setSkills] = useState([]);
    const [certificates, setCertificates] = useState([]);

    const getPersonalDetails = () => {
        axios
            .get(
                `profile/personal`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setPersonalDetails(res.data);
            })
            .catch((err) => {
                
            })
    }

    const getEducationData = () => {
        axios
            .get(
                `profile/education-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setEducationData(res.data);
            })
            .catch((err) => {
                
            })
    }

    const getWorkExperience = () => {
        axios
            .get(
                `profile/workExperience`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setWorkExperience(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getSkills = () => {
        axios
            .get(
                "/profile/skills",
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setSkills(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getCertificate = () => {
        axios
            .get(
                `profile/certificate`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setCertificates(res.data);
            })
            .catch((err) => {
                
            });
    }

    useEffect(() => {
        getPersonalDetails();
        getEducationData();
        getWorkExperience();
        getSkills();
        getCertificate();
    }, []);

    return (
        <div className="font-serif text-neutral-600">
            {personalDetails.map((personal) => (
                <div key={personal}>
                    <div className="flex bg-stone-100 mb-5 px-5 py-3">
                        <div className="flex-grow pt-3">
                            <p className="text-5xl">
                                {personal.first_name} {personal.last_name}
                            </p>
                            <p className="text-xl">
                                {personal.designation}
                            </p>
                        </div>
                        <div>
                            <img src={personal.profile_pic} className="h-28 w-28 rounded-full" alt=""/>
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5 mb-6">
                        <div className="space-y-2 w-2/6">
                            <p className="text-xl font-bold">
                                Contact
                            </p>
                            <Divider/>
                            <p>
                                <CallIcon fontSize={"small"} className="mr-4"/>
                                {personal.mobile_no}
                            </p>
                            <p>
                                <MailIcon fontSize={"small"} className="mr-4"/>
                                {personal.email}
                            </p>
                            <p>
                                <LocationOnIcon fontSize={"small"} className="mr-4"/>
                                {personal.city}, {personal.country}
                            </p>
                            <div className="flex">
                                <LinkedInIcon fontSize={"small"} className="mr-4"/>
                                <p className="break-all">
                                    {personal.linkedin_id}
                                </p>
                            </div>

                        </div>

                        <Divider orientation="vertical" variant="middle" flexItem style={{height: 'auto'}}/>

                        <div className="space-y-2 w-4/6">
                            <p className="text-xl font-bold">
                                Profile
                            </p>
                            <Divider/>
                            <p className="text-justify break-words">
                                Your profile is one of the most important parts of your resume.
                                this section should give a concise summary of your career to date (5 sentences max).
                                this will be one of the first section your future employee/recruiter will see, and you
                                don't want to be the only one!
                                Your profile needs to capture their attention and get them to read your whole CV.
                                One final tip, it is usually easy to write this part last.
                            </p>
                        </div>
                    </div>



                    <div className="flex flex-row space-x-5">
                        <div className="w-2/6">

                            <div className="space-y-2 mb-5">
                                <p className="text-xl font-bold">
                                    Education
                                </p>
                                <Divider/>

                                {educationData.map((eduData) => (
                                    <div key={eduData.id}>
                                        <p className="text-lg font-semibold">
                                            <SchoolIcon fontSize={"small"} className="mr-4"/>
                                            {eduData.course}
                                        </p>
                                        <p className="ml-9 break-all">
                                            {eduData.university_name}, {eduData.pass_year}
                                        </p>
                                    </div>
                                ))}
                            </div>

                            <div className="space-y-2 mb-5">
                                <p className="text-xl font-bold break-after-avoid-page">
                                    Skills
                                </p>
                                <Divider/>
                                {skills.map((skillData) => (
                                    <div key={skillData.id}>
                                        <p className="text-base text-[#98989C] break-all">{skillData.skills}</p>
                                        <input
                                            type="range"
                                            value={skillData.rating * 10}
                                            readOnly
                                            style={{
                                                borderRadius: "20px",
                                                height: "12px",
                                                marginTop: "10px",
                                                width: "80%",
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="space-y-4">
                                <p className="text-xl font-bold">
                                    Certificates
                                </p>
                                <Divider/>
                                {certificates.map((certiData) => (
                                    <div key={certiData.id}>
                                        <p className="font-semibold break-words text-lg">
                                            {certiData.degree_name}
                                        </p>
                                        <p>
                                            {certiData.university_name}
                                        </p>
                                        <p>
                                            {certiData.start_year} - {certiData.end_year}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>


                        <Divider orientation="vertical" variant="middle" flexItem style={{height: 'auto'}}/>

                        <div className="space-y-2 w-4/6">
                            <p className="text-xl font-bold">
                                Work Experience
                            </p>
                            <Divider/>
                            {workExperience.map((workEx) => (
                                <div key={workEx.id} className="mb-4">
                                    <p className="font-semibold text-lg">
                                        {workEx.position}
                                    </p>
                                    <p>
                                            {workEx.company_name} | {workEx.start_date} - {workEx.job_status === "On-Going" ? workEx.job_status : workEx.end_date}
                                    </p>
                                    <p className="break-words text-justify">
                                        {workEx.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default Template1;