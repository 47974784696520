import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Skills.scss";

import { Add } from "@mui/icons-material";
import Cookies from "universal-cookie";
const cookies = new Cookies();

toast.configure();

function Skills(props) {
  const navigate = useNavigate();

  const [projectEdit, setProjectEdit] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/profile/skills", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {

      });
  }, []);


  return (
    <div className="px-0 md:px-10 lg:px-16 py-6 cursor-default">
      <div className="card border-none rounded-lg bg-white mb-3 profile_card">
        <div className="card-body">
          <div className="card-text">
            <div className="skills_main">
              {/*{!projectEdit ? (*/}
              <div className="m-1 md:m-3 lg:m-4">
                <div>
                  <div className=" md:grid md:grid-cols-2 flex items-center justify-between">
                    <div className="text-left">
                      <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">
                        Skills / Strengths
                      </p>
                    </div>
                    <div className="md:text-right px-2 md:px-4">
                      <IconButton
                        onClick={() => {
                          setProjectEdit(true);
                          navigate("/profile/professional-details/skills-edit");
                        }}
                      >
                        {data.length ? (
                          <Button
                            disableElevation
                            endIcon={<EditIcon />}
                            variant="contained"
                            sx={{
                              background: "#F1F1F1",
                              color: "#0865B6",
                              borderRadius: "8px",
                              ":hover": { background: "#E4E4E4" },
                            }}
                          >
                            Edit
                          </Button>
                        ) : (
                          <Add className="text-sky-700 cursor-pointer scale-150 p-0.5" />
                        )}
                      </IconButton>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 lg:grid-cols-2">
                    {data.map((item) =>
                      item.rating !== 0 ? (
                        <div className=" marginTop" key={item.id}>
                          <div className="text-base text-[#98989C] break-all">
                            {item.skills}
                          </div>
                          <input
                            type="range"
                            value={item.rating * 10}
                            readOnly
                            style={{
                              borderRadius: "20px",
                              height: "20px",
                              marginTop: "10px",
                              width: "80%",
                            }}
                          />
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
              {/*) : (*/}
              {/*    <div className="m-1 md:m-3 lg:m-4">*/}

              {/*        <div className="">*/}
              {/*            <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">*/}
              {/*                Edit Skills / Strengths*/}
              {/*            </p>*/}
              {/*        </div>*/}
              {/*        {data.map((item, i) => {*/}
              {/*            return (*/}
              {/*                <div*/}
              {/*                    className="grid grid-col- md:grid-cols-8 lg:grid-cols-12 gap-4 pt-4"*/}
              {/*                    key={item.id}>*/}
              {/*                    <div className="col-span-4">*/}
              {/*                        <TextField*/}
              {/*                            name={`skills`}*/}
              {/*                            label={`${i + 1}. Skill / Strength Name`}*/}
              {/*                            variant="outlined"*/}
              {/*                            type="text"*/}
              {/*                            className="w-56 md:w-full"*/}
              {/*                            id={item.id}*/}
              {/*                            defaultValue={item.skills}*/}
              {/*                            onChange={handleUpdate}*/}
              {/*                            placeholder="Enter your skill / strength here..."*/}
              {/*                        />*/}
              {/*                    </div>*/}

              {/*                    <div className="col-span-4">*/}
              {/*                        <TextField*/}
              {/*                            name="rating"*/}
              {/*                            label="Skill / Strength Level (Out of 10)"*/}
              {/*                            type="number"*/}
              {/*                            className="w-56 md:w-full"*/}
              {/*                            id={item.id}*/}
              {/*                            defaultValue={item.rating}*/}
              {/*                            onChange={handleUpdate}*/}
              {/*                        />*/}
              {/*                    </div>*/}

              {/*                    <div className="col-span-1 pt-2">*/}
              {/*                        <Button*/}
              {/*                            variant="contained"*/}
              {/*                            className="text-white bg-sky-700"*/}
              {/*                            onClick={handleSubmit}*/}
              {/*                        >*/}
              {/*                            Update*/}
              {/*                        </Button>*/}
              {/*                    </div>*/}

              {/*                    <div className="col-span-1 pt-2 pl-14">*/}
              {/*                        <IconButton onClick={(e) => handleDelete(e, item.id)}>*/}
              {/*                            <DeleteIcon className="text-sky-700"/>*/}
              {/*                        </IconButton>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*            )*/}

              {/*        })}*/}

              {/*        {Array.from({length: count}, (_item, i) => (*/}
              {/*            <div key={i} className="grid md:grid-cols-8 lg:grid-cols-12 gap-4 pt-4">*/}
              {/*                <div className="col-span-4">*/}
              {/*                    <TextField*/}
              {/*                        label={`${data.length + i + 1}. Skill / Strength Name`}*/}
              {/*                        name={`skills`}*/}
              {/*                        className="w-52 md:w-full"*/}
              {/*                        type="text"*/}
              {/*                        onChange={handleChange}*/}
              {/*                        placeholder="Enter your skill / strength here..."*/}
              {/*                        // style={{ width: "80%" }}*/}
              {/*                    />*/}
              {/*                </div>*/}
              {/*                <div className="col-span-4">*/}
              {/*                    <TextField*/}
              {/*                        label="Skill / Strength Level (Out of 10)"*/}
              {/*                        type="number"*/}
              {/*                        className="w-52 md:w-full"*/}
              {/*                        defaultValue="0"*/}
              {/*                        onChange={handleChange}*/}
              {/*                        min="1"*/}
              {/*                        name={`rating${i}`}*/}
              {/*                        max="10"*/}
              {/*                        step="0.5"*/}
              {/*                        // style={{ width: "80%" }}*/}
              {/*                    />*/}
              {/*                </div>*/}
              {/*                <div className="col-span-4 pt-2">*/}
              {/*                    <Button*/}
              {/*                        variant="contained"*/}
              {/*                        className="text-white bg-sky-700  border-none"*/}
              {/*                        onClick={handleSubmit}*/}
              {/*                    >*/}
              {/*                        Add*/}
              {/*                        <AddCircleOutlineIcon className="ml-1 p-1"/>*/}
              {/*                    </Button>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*        ))}*/}

              {/*        <div*/}
              {/*            className="grid grid-cols-5 space-y-4 md:grid-cols-10 lg:grid-cols-10 mt-5">*/}
              {/*            <div className="col-span-5">*/}
              {/*                <Button*/}
              {/*                    variant="outlined"*/}
              {/*                    onClick={() => {*/}
              {/*                        setProjectEdit(false);*/}
              {/*                        window.location.reload()*/}
              {/*                    }}*/}
              {/*                >*/}
              {/*                    Cancel*/}
              {/*                </Button>*/}
              {/*            </div>*/}
              {/*            <div className="col-span-5 text-left md:text-right">*/}
              {/*                <Button*/}
              {/*                    variant="contained"*/}
              {/*                    className="rounded-full"*/}
              {/*                    onClick={(e) => setCount(count + 1)}*/}
              {/*                >*/}
              {/*                    Add Another*/}
              {/*                    <AddCircleOutlineIcon className="ml-1 p-1"/>*/}
              {/*                </Button>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*)}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
