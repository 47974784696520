import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import {
  Badge,
  IconButton,
  Tooltip
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "./HRI-logo.jpeg";
import "./Header.scss";
// import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { CameraAlt } from "@mui/icons-material";
import Cookies from "universal-cookie";
import { setHeaderData } from "../features/HeaderDataSlice";
import { toastSuccess } from "../utils/Notification";
import { handleControllerResultException, handleException } from "../utils/dataFetchHelper";
import Notification from "./Notification/Notification";
import Sidebar from "./Sidebar";
import WarningModal from "./WarningModal";
const cookies = new Cookies();


// const StyledMenu = styled((props: MenuProps) => (
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));



const Header = ({ username }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);




  function onClose() {
    setIsOpen(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  // const currentState = useSelector(selectHeader);
  /**
   * @type {import("../features/HeaderDataSlice").HeaderData}
   */
  const headerData = useSelector(state => state.headerData);



  // const visible = () => {
  //   dispatch(
  //     showing({
  //       show: true,
  //     })
  //   );

  //   if (currentState?.show === true) {
  //     dispatch(hiding());
  //   }
  // };


  const GetDetails = () => {

    axios
      .get(`/profile/header-list`, {
        headers: {
          Authorization: `Token ${cookies.get("token")}`,

        }
      })
      .then((res) => {
        dispatch(setHeaderData(res.data))
      })
      .catch((err) => {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate);
      });

  };


  const Logout = () => {
    localStorage.removeItem("redirectPath");
    cookies.remove("token");
    setOpenModal(false)
    navigate("/login");
    toastSuccess("Successfully logged out");
  };

  useEffect(() => {
    if (headerData.isEmpty) {
      GetDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData.isEmpty]);

  // const [openDialog, setOpenDialog] = useState(false);

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  return (
    <div className="header_main" style={{ position: "fixed", width: "100%", top: "0", zIndex: "100", background: "white" }}>
      <Sidebar open={isOpen} onClose={onClose} />
      <div className="header_parent_div">
        <IconButton
          onClick={() => setIsOpen(true)}
          className="header_nav_icon_div"
        >
          <MenuIcon className="header_nav_icon" />
        </IconButton>
        <div className="logo-container space-x-2 text-base md:text-xl lg:text-xl flex items-center">
          <Avatar
            src={headerData.logo || logo}
            sx={{ width: 38, height: 38 }}
            className="border-2 border-slate-300 p-[2px] rounded-full"
          />

          <div className="font-semibold">{headerData.name || "HR Intelligence"}</div>
        </div>
        {/* {info? (
                    <div key={data.data} className="space-x-0 flex sm:space-x-1 md:space-x-3">
                        <IconButton
                            onClick={() => {
                                handleOpenDialog();
                                handleClose();
                            }}>
                            <Badge badgeContent={dataLen} color="primary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton onClick={() => navigate("/message")}>
                            <EmailIcon />
                        </IconButton>
                        <div className="md:p-[2px] flex overflow-hidden">
                            <p className="text-base md:text-xl lg:text-xl text-[#0865B6] hidden md:block m-auto">{data.first_name}</p>

                            <IconButton
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                onClick={handleClick} >
                                <div className="border-1 p-[1px] border-gray-500 rounded-full" >
                                    {data.profile_pic !== null ? <img src={data.profile_pic} className="h-10 w-10  rounded-full" />
                                        : <Avatar className="h-10 w-10" />}
                                    {/* <CameraAlt className='absolute right-1 bottom-1 bg-white scale-[.6] rounded-lg border-1 border-gray-500' /> */}
        {/* </div>
                            </IconButton>

                        </div>
                    </div>)) */}
        <div className="space-x-0 sm:space-x-1 flex md:space-x-3">
          <IconButton

          >
            <Notification />
          </IconButton>
          <IconButton onClick={() => navigate(`/messages/Received`)}>
            <Tooltip title="Message">
              <Badge badgeContent={headerData.unread_msg_count} color="primary">
                <EmailIcon />
              </Badge>
            </Tooltip>
          </IconButton>
          <IconButton
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            onClick={handleClick}
          >
            <div className="border-1 p-[1px] border-gray-500 rounded-full">
              <Avatar className="h-10 w-10" src={headerData.user_image} alt="user"></Avatar>
              <CameraAlt className="absolute z-1 right-1 bottom-1 bg-white scale-[.6] rounded-lg border-1 border-gray-500" />
            </div>
          </IconButton>
        </div>
        <div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => navigate("/profile/personal")}
              disableRipple
            >
              <PersonIcon />
              MyProfile
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/profile/settings")}
              disableRipple
            >
              <SettingsIcon />
              Settings
            </MenuItem>
            <MenuItem onClick={() => navigate("/help")} disableRipple>
              <HelpIcon />
              Help & FAQ
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => setOpenModal(true)} disableRipple>
              <LogoutIcon />
              Logout
            </MenuItem>
            <WarningModal
              open={openModal}
              close={() => setOpenModal(false)}
              title="Logout ?"
              content="Are you sure you want to Logout ?"
              onclickfunction={Logout}
              cancelText="No"
              agreeText="Yes"
            />
          </StyledMenu>
        </div>

      </div>
    </div>
  );
};

export default Header;
