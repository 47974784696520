import axios from "axios";
import { toast } from "react-toastify";
import {base_url} from "../components/consts";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const getRequest = (endPoint, setData) => {
  axios
    .get(`${base_url}/${endPoint}`, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      
      toast.success("Getting Response");
      setData(res.data);
    })
    .catch((err) => {
      
      toast.error("Some error occurred");
    });
};

const postRequest = (endPoint, data, path) => {
  axios
    .post(`${base_url}/${endPoint}`, data, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      
      toast.success("Getting Response");
      // window.location.replace(path);
    })
    .catch((err) => {
      
      toast.error("Some error occurred");
    });
};

const updateRequest = (endPoint, data, path) => {
  axios
    .put(`${base_url}/${endPoint}`, data, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      
      toast.success("Getting Response");
      window.location.replace(path);
    })
    .catch((err) => {
      
      toast.error("Some error occurred");
    });
};

const deleteRequest = (endPoint, path) => {
  axios
    .get(`${base_url}/${endPoint}`, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    })
    .then((res) => {
      
      toast.success("Getting Response");
      window.location.replace(path);
    })
    .catch((err) => {
      
      toast.error("Some error occurred");
    });
};

export { getRequest, postRequest, updateRequest, deleteRequest };