import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../../components/Header";
import Editor from "../../components/richEditor/Editor";
import { addProfileDetails, getProfileDetails, updateProfileData } from "../../controller/profileController";
import useCreateEditor from "../../hooks/useCreateEditor";
import useLoading from "../../hooks/useLoading";
import { toastSuccess } from "../../utils/Notification";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { HTTPStatus } from "../../utils/helperObj";
import { validatingProfileFormInput } from "./validation/index";
export default function ProfileForm() {
    /**
     * @type {"add" |"update"}
     */
    const { type } = useParams()


    const fetchingProfileInfo = useLoading();
    const savingProfileInfo = useLoading();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [profilePic, setProfilePic] = useState();
    const [formData, setFormData] = useState({
        profilePicLink: "http",
        firstName: "",
        lastName: "",
        designation: "",
        phoneNumber: "",
        maritalStatus: "",
        aadharCardNumber: "",
        panCardNumber: "",
        dateOfBirth: "",
        gender: "",
        currentAddress: "",
        parmanentAddress: "",
        language: "",
        totalExperience: "",
        pinCode: "",
        city: "",
        state: "",
        country: "",
        guardianName: "",
        guardianNumber: "",
        aboutYou: "",
        linkedInLink: "",
        githubLink: "",
    });

    const editor = useCreateEditor(formData.aboutYou);

    const handleChange = (e) => {
        const type = e.target.type;
        const name = e.target.name;
        const value = e.target.value;
        if (type === "file") {
            const image = e.target.files[0];
            setProfilePic(image);
            image
                ? setFormData((prevState) => ({
                    ...prevState,
                    profilePicLink: URL.createObjectURL(image),
                }))
                : setFormData((prevState) => ({
                    ...prevState,
                    profilePicLink: "http",
                }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    const handleFetchProfileDetails = useCallback(async () => {
        fetchingProfileInfo.setLoading(true);
        const res = await getProfileDetails();
        fetchingProfileInfo.setLoading(false);
        if (res[0] !== null) {
            setFormData(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, []);

    useEffect(() => {
        if (type === "update") {
            handleFetchProfileDetails()
        }
    }, [handleFetchProfileDetails, type]);

    function createFormData() {
        const formObj = new FormData();

        const data = {
            "first_name": formData.firstName,
            "last_name": formData.lastName,
            "mobile_no": formData.phoneNumber,
            "gender": formData.gender,
            "dob": formData.dateOfBirth,
            "address": formData.parmanentAddress,
            "pin": formData.pinCode,
            "city": formData.city,
            "state": formData.state,
            "country": formData.country,
            "marital_status": formData.maritalStatus,
            "about_you": editor.getHTML(),
            "current_address": formData.currentAddress,
            "language": formData.language,
            "designation": formData.designation,
            "linkedin_id": formData.linkedInLink,
            "github_id": formData.githubLink,
            "aadhaar_card": formData.aadharCardNumber,
            "pan_card": formData.panCardNumber,
            "guardian_name": formData.guardianName,
            "guardian_phn_no": formData.guardianNumber,
            "total_exp": formData.totalExperience,
        }


        const keys = Object.keys(data);
        for (let x of keys) {
            formObj.append(x, data[x]);
        }
        return formObj;
    }

    async function handleUpdateProfileDetails() {
        if (validatingProfileFormInput(formData)) {
            const formData = createFormData();
            profilePic && formData.append("profile_pic", profilePic);
            savingProfileInfo.setLoading(true);
            const res = await updateProfileData(formData);
            savingProfileInfo.setLoading(false)
            if (res.statusCode === HTTPStatus.SUCCESS) {
                toastSuccess(res.successMsg)
            } else {
                handleControllerResultException(res, navigate)
            }

        } else {
            setShowError(true)
        }

    }

    async function handleAddProfileDetails() {
        if (validatingProfileFormInput(formData)) {
            const formObj = createFormData();
            profilePic && formObj.append("profile_pic", profilePic);
            savingProfileInfo.setLoading(true);
            const res = await addProfileDetails(formObj);
            savingProfileInfo.setLoading(false);
            if (res.statusCode === HTTPStatus.CREATED) {
                toastSuccess(res.successMsg)
                navigate(-1)
            } else {
                handleControllerResultException(res, navigate)
            }
        } else {
            setShowError(true)

        }
    }

    if (fetchingProfileInfo.loading) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"3rem"} />
            </Box>
        );
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="xl" sx={{ margin: "5rem auto" }}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Button onClick={() => navigate(-1)}><KeyboardArrowLeftIcon /> back</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={type === "update" ? handleUpdateProfileDetails : handleAddProfileDetails} variant="contained" disabled={savingProfileInfo.loading} sx={{ width: "100px", height: "35px" }}>
                                {savingProfileInfo.loading ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "Save"}
                            </Button>
                        </Box>
                    </Grid>
                    {formData.profilePicLink !== "http" && (
                        <Grid item xs={12}>
                            <Avatar
                                alt={"profile image"}
                                src={formData.profilePicLink}
                                sx={{ width: 80, height: 80 }}
                            />
                        </Grid>
                    )}
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="Profile image"
                            onChange={handleChange}
                            type="file"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="First name"
                            name="firstName"
                            onChange={handleChange}
                            value={formData.firstName}
                            type="text"
                            variant="standard"
                            fullWidth
                            error={showError && formData.firstName === ""}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            label="Last name"
                            name="lastName"
                            onChange={handleChange}
                            value={formData.lastName}
                            type="text"
                            variant="standard"
                            fullWidth
                            error={showError && formData.lastName === ""}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="gender">Gender</InputLabel>
                            <Select
                                error={showError && formData.gender === ""}
                                labelId="gender"
                                value={formData.gender}
                                name="gender"
                                onChange={handleChange}
                                label="Gender"
                                variant="standard"
                            >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Not Specified"}>Not Specified</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.designation === ""}
                            label="Designation"
                            name="designation"
                            onChange={handleChange}
                            value={formData.designation}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.phoneNumber.length !== 10}
                            label="Phone number (must be 10 digit)"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={formData.phoneNumber}
                            type="number"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="marital-status">Marital status</InputLabel>
                            <Select
                                error={showError && formData.maritalStatus === ""}
                                labelId="marital-status"
                                value={formData.maritalStatus}
                                name="maritalStatus"
                                onChange={handleChange}
                                label="Marial status"
                                variant="standard"
                            >
                                <MenuItem value={"Single"}>Single</MenuItem>
                                <MenuItem value={"Married"}>Married</MenuItem>
                                <MenuItem value={"Widow"}>Widow</MenuItem>
                                <MenuItem value={"Divorced"}>Divorced</MenuItem>
                                <MenuItem value={"Single"}>Other </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && (formData.aadharCardNumber !== "" && formData.aadharCardNumber.length !== 12)}
                            label="Aadhar card number (must be 12 digit) (optional)"
                            name="aadharCardNumber"
                            onChange={handleChange}
                            value={formData.aadharCardNumber}
                            type="number"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && (formData.panCardNumber !== "" && formData.panCardNumber.length > 10)}
                            label="Pan card number (maximum 10 character) (optional)"
                            name="panCardNumber"
                            onChange={handleChange}
                            value={formData.panCardNumber}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.dateOfBirth === ""}
                            label="Date of birth"
                            name="dateOfBirth"
                            onChange={handleChange}
                            value={formData.dateOfBirth}
                            type="date"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={showError && formData.parmanentAddress === ""}
                            label="Parmanent address"
                            name="parmanentAddress"
                            onChange={handleChange}
                            value={formData.parmanentAddress}
                            type="text"
                            multiline
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={showError && formData.currentAddress === ""}
                            label="Current address"
                            name="currentAddress"
                            onChange={handleChange}
                            value={formData.currentAddress}
                            type="text"
                            multiline
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.language === ""}
                            label="Language"
                            name="language"
                            onChange={handleChange}
                            value={formData.language}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="experience">Total experiance</InputLabel>
                            <Select
                                error={showError && formData.totalExperience === ""}
                                labelId="experience"
                                value={formData.totalExperience}
                                name="totalExperience"
                                label="Total experiance"
                                variant="standard"
                                onChange={handleChange}
                            >
                                <MenuItem value={"0 to 1"}>0 to 1 Years</MenuItem>
                                <MenuItem value={"1 to 2"}>1 to 2 Years</MenuItem>
                                <MenuItem value={"3 to 5"}>3 to 5 Years</MenuItem>
                                <MenuItem value={"5+"}>5+ Years</MenuItem>
                                <MenuItem value={"10+"}>10+ Years</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.city === ""}
                            label="City"
                            name="city"
                            onChange={handleChange}
                            value={formData.city}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.state === ""}
                            label="State"
                            name="state"
                            onChange={handleChange}
                            value={formData.state}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.country === ""}
                            label="Country"
                            name="country"
                            onChange={handleChange}
                            value={formData.country}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <TextField
                            error={showError && formData.pinCode === ""}
                            label="Pin code"
                            name="pinCode"
                            onChange={handleChange}
                            value={formData.pinCode}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Guardian name (optional)"
                            name="guardianName"
                            onChange={handleChange}
                            value={formData.guardianName}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={showError && (formData.guardianNumber !== "" && formData.guardianNumber.length !== 10)}
                            label="Guardian number (must be 10 digit) (optional)"
                            name="guardianNumber"
                            onChange={handleChange}
                            value={formData.guardianNumber}
                            type="number"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Editor editor={editor} heading="About you" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Linkedin Link (optional)"
                            name="linkedInLink"
                            onChange={handleChange}
                            value={formData.linkedInLink}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Github Link (optional)"
                            name="githubLink"
                            onChange={handleChange}
                            value={formData.githubLink}
                            type="text"
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
