/**
 *
 * @param {object} inputs
 */


export function validatingProfileFormInput(inputs) {
    const keys = Object.keys(inputs);
    return keys.every((key) => validateField(key, inputs));
}
function validateField(key, inputs) {
    if (key === "profilePicLink") {
        return true;
    } else if (key === "aboutYou") {
        return true
    } else if (key === "panCardNumber") {
        return inputs["panCardNumber"] === "" ? true : (inputs["panCardNumber"].length <= 10 ? true : false);
    } else if (key === "aadharCardNumber") {
        return inputs["aadharCardNumber"] === "" ? true : (inputs["aadharCardNumber"].length === 12 ? true : false);
    } else if (key === "phoneNumber") {
        return inputs["phoneNumber"].length === 10 ? true : false
    } else if (key === "guardianNumber") {
        return inputs["guardianNumber"] === "" ? true : (inputs["guardianNumber"].length === 10 ? true : false);
    } else if (
        key === "linkedInLink" ||
        key === "githubLink" ||
        key === "guardianName"
    ) {
        return true;
    } else {
        return inputs[key] !== "" ? true : false;
    }
}
