import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { base_url } from "../../../../components/consts";
import { isValidWorkExp } from "../../../../helpers/validate_workexp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const WorkExperienceUpdate = () => {
  const cookies = new Cookies();
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [variable, setVariable] = useState({
  //   position: "",
  //   company: "",
  //   city: "",
  //   country: "",
  //   start_date: null,
  //   end_date: null,
  //   status: "",
  //   responsibilities: "",
  // });

  const [id, setId] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [status, setStatus] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  // const [data, setData] = useState([]);

  // const startDate = moment(start_date).format("YYYY-MM-DD");
  // const endDate = moment(end_date).format("YYYY-MM-DD");

  // const data = {
  //     company_name: company,
  //     position: position,
  //     job_status: status,
  //     city: city,
  //     country: country,
  //     start_date: startDate,
  //     end_date: endDate,
  //     description: responsibilities,
  // };

  // 

  const WorkExID = localStorage.getItem("WorkEx-ID");

  useEffect(() => {
    axios
      .get(`${base_url}/profile/workExperience/${WorkExID}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        
        setId(res.data[0].id);
        setPosition(res.data[0].position);
        setCompany(res.data[0].company_name);
        setCity(res.data[0].city);
        setCountry(res.data[0].country);
        setStartDate(res.data[0].start_date);
        setEndDate(
          res.data[0].end_date === "Invalid date" ? null : res.data[0].end_date
        );
        setStatus(res.data[0].job_status);
        setResponsibilities(res.data[0].description);
        
      })
      .catch((err) => {
        
      });
  }, []);

  const updateExperience = () => {
    let formField = new FormData();

    const data = {
      company_name: company,
      position: position,
      job_status: status,
      city: city,
      country: country,
      start_date: start_date,
      is_completed: status === "Completed" ? true : false,
      end_date: end_date,
      description: responsibilities,
    };

    const error = isValidWorkExp(data);
    if (error) return toast.error(error);

    // Convert the data object into the FormData
    Object.entries(data).forEach(([key, value]) => {
      formField.append(key, value);
    });

    if (status === "On-Going") {
      axios
        .put(
          `${base_url}/profile/workExperience/update/${WorkExID}`,
          formField,
          {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
          }
        )
        .then((res) => {
          
          navigate("/profile/professional-details/work-experience");
          toast.success("Updated sucessfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          });
        })
        .catch((err) => {
          
          toast.error("Error updating", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        });
    } else if (status === "Completed") {
      if (start_date < end_date) {
        axios
          .put(
            `${base_url}/profile/workExperience/update/${WorkExID}`,
            formField,
            {
              headers: {
                Authorization: "Token " + cookies.get("token"),
              },
            }
          )
          .then((res) => {
            
            navigate("/profile/professional-details/work-experience");
            toast.success("Updated successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              style: { backgroundColor: "#1ab394", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#BAE8DE" },
            });
          })
          .catch((err) => {
            
            toast.error("Error updating", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              style: { backgroundColor: "#FF6347", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#FFB1A3" },
            });
          });
      } else {
        toast.error("End date cannot be before Start date", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      }
    }
  };

  const deleteExperience = () => {
    axios
      .delete(`${base_url}/profile/workExperience/delete/${id}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        navigate("/profile/professional-details/work-experience");
        toast.success("Deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {
        
      });
  };

  
  return (
    <div>
      <div className="card text-dark bg-white my-4 border-none rounded-lg">
        <div className="card-body">
          <div className="card-text">
            <div className="grid grid-cols-1 md:grid-cols-2 space-y-4 flex items-center justify-between">
              <div>
                <h1 className="text-lg font-bold text-[#0865B6]">
                  Edit Work Experience
                </h1>
              </div>

              <div className="space-x-5 text-left md:text-right">
                <Button
                  className="text-base rounded-lg px-3 py-1 "
                  onClick={handleOpen}
                  style={{
                    background: "#F1F1F1",
                    color: "#0865B6",
                    fontWeight: "bold",
                  }}
                >
                  <DeleteIcon style={{ fontSize: "large" }} /> Delete
                </Button>
              </div>
            </div>

            <div className="m-2 pt-4 space-y-3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="flex flex-col space-y-1">
                  <TextField
                    label="Job Position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    type="text"
                    // placeholder="Enter your job role / position"
                    // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <TextField
                    label="Company Name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    fullWidth
                    label="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    type="text"
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-customized-select-label">
                      Job Status
                    </InputLabel>
                    <Select
                      label="Job Status"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <MenuItem value={"On-Going"}>On-Going</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex flex-col">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label="Start Date"
                      value={start_date}
                      openTo="day"
                      views={["year", "month", "day"]}
                      onChange={(newValue) =>
                        setStartDate(moment(newValue).format("YYYY-MM-DD"))
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {status === "Completed" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="flex flex-col col-span-1">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        label="End Date"
                        value={end_date}
                        openTo="day"
                        views={["year", "month", "day"]}
                        onChange={(newValue) =>
                          setEndDate(moment(newValue).format("YYYY-MM-DD"))
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}
              <div className="flex flex-col space-y-1 col-span-2">
                <TextField
                  label="Job Responsibilities"
                  value={responsibilities}
                  onChange={(e) => setResponsibilities(e.target.value)}
                  rows="4"
                  multiline
                />
              </div>
            </div>
            <div className="my-4 space-x-5">
              <Button
                className={`text-lg bg-[#F1F1F1] rounded-lg px-4 py-2 font-semibold text-[] duration-300 ${
                  position === "" ||
                  country === "" ||
                  company === "" ||
                  city === "" ||
                  start_date === "" ||
                  end_date === "" ||
                  status === "" ||
                  responsibilities === ""
                    ? "opacity-50"
                    : "opacity-100"
                }`}
                disabled={
                  position === "" ||
                  country === "" ||
                  company === "" ||
                  city === "" ||
                  start_date === "" ||
                  end_date === "" ||
                  status === "" ||
                  responsibilities === ""
                    ? true
                    : false
                }
                onClick={updateExperience}
                style={{
                  background: "#F1F1F1",
                  color: "#0865B6",
                  borderRadius: "0.5rem",
                }}
              >
                <span>
                  <DoneAllIcon />
                </span>{" "}
                Save
              </Button>
              <Button
                className={`text-lg bg-[#F1F1F1] rounded-lg px-4 py-2 font-semibold duration-300 `}
                style={{
                  background: "#F1F1F1",
                  color: "#0865B6",
                  borderRadius: "0.5rem",
                }}
                onClick={() =>
                  navigate("/profile/professional-details/work-experience")
                }
              >
                <span>
                  <CloseIcon style={{ fontSize: "large" }} />
                </span>{" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="w-3/4 md:w-1/3">
            <p id="transition-modal-title" className="text-lg md:text-xl">
              Confirm Deleting the experience?
            </p>
            <p>Please confirm</p>
            <Typography className="mt-2">
              <button
                className="bg-red-400 hover:bg-red-200 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 md:text-base rounded"
                onClick={deleteExperience}
              >
                Yes
              </button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default WorkExperienceUpdate;
