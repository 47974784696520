import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { saveServeyAnswer } from "../../controller/serveyController";
import { HTTPStatus } from "../../utils/helperObj";
import { useNavigate } from "react-router";
import { handleControllerResultException } from "../../utils/dataFetchHelper";

/**
 * @typedef {object} Props
 * @property {import('../../model/ServeyModel').ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */
export default function QuestionCheckBoxField({
    question,
}) {
    const componentMounted = useRef(false);
    const [checkedFileds, setCheckedFields] = useState(generateFieldObj());
    const navigate = useNavigate();
    const timeoutRef = useRef(null)

    function generateFieldObj() {
        const checkObj = {};
        const answerList =
            question.answer !== null ? question.answer.split(",") : [];
        for (let x of question.options) {
            if (answerList.includes(x)) {
                checkObj[x] = true;
            } else {
                checkObj[x] = false;
            }
        }
        return checkObj;
    }

    const handleAnswer = useCallback(async (checkedFileds) => {
        const selectedAnswer = [];
        for (const x in checkedFileds) {
            checkedFileds[x] === true && selectedAnswer.push(x);
        }
        const res = await saveServeyAnswer(
            question.id,
            selectedAnswer.toString(),
            question.questionType
        );
        if (res.statusCode !== HTTPStatus.CREATED) {
            handleControllerResultException(res, navigate);
        }
    }, []);

    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (componentMounted.current) {
            timeoutRef.current = setTimeout(() => {
                handleAnswer(checkedFileds);
            }, "700");
        } else {
            componentMounted.current = true;
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [checkedFileds, handleAnswer]);

    return (
        <FormGroup>
            {Object.keys(checkedFileds).length > 0 &&
                question.options.map((data, index) => (
                    <FormControlLabel
                        key={index}
                        label={data}
                        control={
                            <Checkbox
                                onChange={(e) =>
                                    setCheckedFields((prevState) => ({
                                        ...prevState,
                                        [data]: e.target.checked,
                                    }))
                                }
                                checked={checkedFileds[data]}
                            />
                        }
                    />
                ))}
        </FormGroup>
    );
}
