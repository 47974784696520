/* eslint-disable jsx-a11y/alt-text */
import { Visibility } from "@mui/icons-material";
import { Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { EmptyState } from "../../components/EmptyStates/EmptyState";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { base_url } from "../../components/consts";
import MessageDetailModel from "../../components/messages/MessageDetailModel";
import noApplication from "../../images/empty_state/no-dashboardapplication.png";
import noJob from "../../images/empty_state/no-dashboardjob.png";
import noMessage from "../../images/empty_state/no-dashboardmessage.png";
import Contact from "./Contact";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import Scr from "./srceen.png";

const cookies = new Cookies();

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [jobNumber, setJobNumber] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(null);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [showMessageDetailModal, setShowMessageDetail] = useState({
    visible: false,
    message: {},
  });


  async function getData() {
    await axios
      .get(`${base_url}/profile/dashboard`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        setData(resp.data.unread_msg);
        setJobTitle(resp.data.job_title);
        setJobNumber(resp.data.job_title_application_no);
        setLoading(false);
        setPieData(resp.data.pie_chart);
        setTotal(resp.data.total_application);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const [meetings, setMeetings] = useState([]);

  const today = new Date();

  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthValue = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const dateValue = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const date = dateValue[today.getDate() - 1];
  const month = today.getMonth();
  const monthValue2 = monthValue[month];
  const year = today.getFullYear();

  const getMeetings = () => {
    axios
      .get(
        `${base_url}/profile/meeting-by-date/${year}-${monthValue2}-${date}`,
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        setMeetings(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (cookies.get("token")) {
      getMeetings();
      getData();
    } else {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <div className="bg-default-background pb-10 w-full h-screen overflow-auto">
        <Header />
        <Sidebar />
        <div className="px-4 py-7">
          <h1 className="font-bold text-[#0865B6] text-2xl">
            Welcome {user?.name}
          </h1>
          {/*<p className="text-[#919090] font-semibold">*/}
          {/*    We hope today you will get good profiles to build your great team*/}
          {/*</p>*/}
          <div className="flex flex-col xl:flex-row my-4 w-full gap-4">
            <div className="flex flex-col lg:flex-row justify-evenly md:gap-4 lg:gap-5 ">
              <div>
                {pieData.length ? (
                  <PieChart pieData={pieData} total={total} />
                ) : (
                  <div className="bg-white font-bold min-h-[350px] flex flex-col lg:min-w-[22rem]">
                    <h1 className="pl-8 pt-8 font-semibold text-lg">
                      Total Applications
                    </h1>
                    <EmptyState
                      img={noApplication}
                      title="No Applications"
                      message="You have currently no applications to display.Check later for updates!"
                    />
                  </div>
                )}
              </div>
              <div className="pt-4 md:mt-[-24px]">
                {jobTitle.length ? (
                  <LineChart jobTitle={jobTitle} jobNumber={jobNumber} />
                ) : (
                  <div className="bg-white font-bold min-h-[350px] flex flex-col lg:min-w-[22rem]">
                    <h1 className="pl-8 pt-8 font-semibold text-lg">
                      Top Jobs
                    </h1>
                    <EmptyState
                      img={noJob}
                      title="No Jobs"
                      message="There are currently no active job listings by companies. Check back later for updates!"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="bg-[#0865B6] pb-5 text-white w-full rounded-lg px-4">
              <div className="flex pt-3">
                <div className="text-5xl">
                  <p className="flex pr-2">{date}</p>
                </div>
                <div className="flex flex-col text-xl -mt-1">
                  <div className="flex">{monthList[month]}</div>
                  <div className="-mt-1">{year}</div>
                </div>
              </div>

              <p className="text-lg px-4">MEETINGS</p>

              {meetings.length ? (
                <div className="cScroll px-4 space-y-2 h-32 overflow-y-auto">
                  {meetings.map((meet) => (
                    <div key={meet.id}>
                      <div className="flex flex-col items-center  px-2 py-2 gap-2 bg-[#FDFDFD80] text-[16px] md:flex-row md:gap-6">
                        <div className="whitespace-nowrap">
                          {meet.start_time}- {meet.end_time}
                        </div>
                        <div className="flex-grow truncate">{meet.title}</div>
                        <Link to="/calendar">
                          <Visibility sx={{ color: "white" }} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-center pt-4">
                  <p className="text-xl ">No meetings Scheduled for today</p>
                </div>
              )}
            </div>
          </div>
          <div className="lg:flex gap-4 space-y-4 lg:space-y-0 ">
            <div className="flex flex-col w-full">
              <div
                className="bg-[#0865B6] border-b-2 sticky top-0 border-gray-200 flex items-center justify-center 
              text-white text-lg font-bold p-1 rounded-t-lg"
              >
                New Unread Message
              </div>
              <div className="  w-full ">
                <div className=" ease-in  duration-300  ml-0 ">
                  <div className="overflow-auto rounded-lg w-full h-[60vh] shadow  ">
                    {data.length ? (
                      <table className="w-full">
                        <thead className="bg-slate-200 border-b-2 sticky top-0 border-gray-200">
                          <tr className="text-blue-700 ">
                            <th className="w-20 p-3 pl-10 text-lg font-semibold tracking-wide text-center">
                              Sender
                            </th>
                            <th className="w-24 p-3 text-lg font-semibold tracking-wide ">
                              Message
                            </th>
                            <th className="w-10 p-3 text-lg font-semibold tracking-wide text-center">
                              Date
                            </th>
                            <th className="w-24 p-3 text-lg font-semibold tracking-wide text-center">
                              Details
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-100  text-center">
                          {data.map((user) => {
                            cookies.set("id", user.id);
                            const date = new Date(user.updated_at.slice(0, 10));
                            const month = date.toLocaleString("default", {
                              month: "long",
                            });

                            return (
                              <tr
                                key={user.id}
                                // onClick={() => getMessage(user.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                className="bg-white cursor-pointer hover:bg-slate-100"
                              >
                                <td className="p-3  pl-10 text-base text-gray-700 whitespace-nowrap">
                                  {user.sender_name}
                                </td>
                                <td className="p-3  MessageLine text-base text-gray-700 text-start">
                                  <div className=" text-lg">{user.header}</div>
                                </td>
                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                  {date.getDate()}&nbsp;
                                  {month.slice(0, 3)}&nbsp;
                                  {date.getFullYear()}
                                </td>
                                <td className="p-3 text-base text-gray-700 whitespace-nowrap">
                                  <div
                                    onClick={() => {
                                      setShowMessageDetail({
                                        visible: true,
                                        message: user,
                                      });
                                    }}
                                    className="bg-slate-100  py-2 rounded-lg text-blue-600 "
                                  >
                                    View Message
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex justify-center w-full">
                        {loading ? (
                          <div className="flex justify-center w-full">
                            <Spinner />
                          </div>
                        ) : (
                          <div className="bg-white font-bold min-h-[350px] flex flex-col lg:min-w-[60rem]">
                            <h1 className="pl-8 pt-8 font-semibold text-lg">
                              New messages
                            </h1>
                            <EmptyState
                              img={noMessage}
                              title="No Messages"
                              message="There are currently no messages to display. Stay connected and check back later for any new messages"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white  lg:mt-0 md:w-full  h-[65vh] xl:w-[80vh]  rounded-lg ">
              <div className=" w-full flex justify-center">
                <div className="md:pt-10 lg:pt-5 xl:pt-20">
                  <img src={Scr} title="image" className="" />
                </div>
              </div>
              <div className="text-2xl px-10 mt-11  w-full">Need Help ?</div>
              <div className="px-10  text-lg text-gray-500">
                Admin Contact information shown here
              </div>
              <div className="px-10 mt-2">
                <Button
                  onClick={() => setOpenContactUsModal(true)}
                  type="primary"
                  style={{ borderRadius: "5px" }}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MessageDetailModel
        message={showMessageDetailModal.message}
        open={showMessageDetailModal.visible}
        isUnreadMessage={true}
        setMessageList={setData}
        onClose={() => {
          setShowMessageDetail({ visible: false, message: {} });
        }}
      />
      <Contact
        open={openContactUsModal}
        onClose={() => setOpenContactUsModal(false)}
      />
    </>
  );
};

export default Home;
