import React from "react";
import ModalLayout from "../BaseModal";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";

export default function StartAssessmentWarning({ open, onClose, assessmentId }) {
    const navigate = useNavigate();
    return (
        <ModalLayout
            open={open}
            onClose={onClose}
            sx={{
                padding: "1rem 1.5rem",
                width: {
                    md: "400px",
                    xs: "70%",
                },
            }}
        >
            <Typography>Do you want to start assessment?</Typography>
            <Box
                marginTop={"1.3rem"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
            >
                <Button
                    onClick={() => onClose()}
                    sx={{ fontSize: "12px", color: "#212121" }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => navigate(`/servey/${assessmentId}`)}
                    variant="contained"
                >Start</Button>
            </Box>
        </ModalLayout>
    );
}
