import {
    Box,
    Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import QuestionTextField from "./QuestionTextField";
import QuestionCheckBoxField from "./QuestionCheckBoxField";
import QuestionFileField from "./QuestionFileField";
import QuestionRadioGroup from "./QuestionRadioGroup";
import QuestionBooleanField from "./QuestionBooleanField";

/**
 * @typedef {object} Props
 * @property {import('../../model/ServeyModel').ServeyQuestionModel} question
 */

/**
 * @param {Props} props
 */
export default function QuestionCard({ question }) {



    function renderInputField() {
        if (question.questionType === "ChoiceField") {
            return (
                <QuestionCheckBoxField
                    question={question}
                />
            );
        } else if (question.questionType === "TextField") {
            return (
                <QuestionTextField
                    question={question}
                />
            );
        } else if (
            question.questionType === "FileField" ||
            question.questionType === "VideoField" ||
            question.questionType === "ImageField"
        ) {
            return (
                <QuestionFileField
                    question={question}
                />
            );
        } else if (question.questionType === "RadioField") {
            return (
                <QuestionRadioGroup question={question} />
            );
        } else if (question.questionType === "BoolField") {
            return (
                <QuestionBooleanField
                    question={question}
                />
            );
        }
    }


    return (
        <Box paddingY={"1rem"} >
            <Typography variant="h5" fontSize={"16px"} fontWeight={"500"}>
                {question.question}
            </Typography>

            {question.description !== "" && (
                <Typography
                    marginTop={"5px"}
                    variant="body1"
                    fontSize={"13px"}
                    color={grey[700]}
                >
                    {question.description}
                </Typography>
            )}
            <Box marginTop={"10px"}>
                {renderInputField()}
            </Box>

        </Box>
    );
}
