import axios from "axios";
import { handleException } from "../utils/dataFetchHelper";
import { API } from "../utils/helperObj";
import Cookies from "universal-cookie";
import HttpResponse from "../model/HttpResponseModel";

/**
 * @typedef {object} JobListItemModel
 * @property {number} id
 * @property {string} company
 * @property {string} company_logo
 * @property {string} role_sub_category_name
 * @property {string} location
 * @property {string} employment_type
 * @property {string} job_unique_id
 * @property {string} stipend
 * @property {string} position_name

 */





const cookies = new Cookies();



export async function jobApply(jobId) {
    try {
        const res = await axios.post(
            `${API.job.applyJob}`,
            { position: jobId },
            {
                headers: {
                    Authorization: `Token ${cookies.get("token")}`,
                },
            }
        );
        return new HttpResponse({
            statusCode: res.status,
            successMsg: "Job applied successfuly",
        });
    } catch (err) {
        return handleException(err);
    }
}
