import React from "react";
import MenuBar from "./MenuBar";
import { EditorContent } from "@tiptap/react";

/**
 * @typedef {object} Props
 * @property {import("@tiptap/react").Editor | null} editor
 * @property {string} heading
 * @property {React.CSSProperties} styles
 */

/**
 * @param {Props} props

 */
export default function RichEditor({ editor, heading = "", styles = {} }) {
  return (
    <div style={{ ...styles }}>
      {heading !== "" && (
        <h3
          style={{
            fontWeight: "400",
            fontSize: "20px",
            color: "#66747f",
            marginBottom: "1rem",
          }}
        >
          {heading}
        </h3>
      )}
      <MenuBar editor={editor} />
      <div
        style={{
          borderRadius: 10,
          marginTop: "1rem",
        }}
      >
        <EditorContent
          editor={editor}
          style={{
            color: "#212121",
          }}
        />
      </div>
    </div>
  );
}