import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { sendOtpToEmail, verifyOtp } from "../../controller/ForgotPasswordController";
import { HTTPStatus } from "../../utils/helperObj";
import { toastError, toastSuccess } from "../../utils/Notification";
import { isValidEmail } from "../../utils/validation";
import { message } from "antd";
import useLoading from "../../hooks/useLoading";

/**
 * @typedef {object} Params
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOtpVerified
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setToken
 * @property {string} email
 */

/**
 * @param {Params} params
 */

export default function VerifiedOTP({ setOtpVerified, setToken }) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const sendingOtpStatus = useLoading();
  const verifyingOtpStatus = useLoading();


  const sendOtpHandler = async () => {
    if (isValidEmail(email)) {
      sendingOtpStatus.setLoading(true);
      const res = await sendOtpToEmail(email);
      sendingOtpStatus.setLoading(false);
      if (res.statusCode === HTTPStatus.SUCCESS) {
        toastSuccess(res.successMsg);
      } else {
        toastError(res.errorMsg);
      }
    } else {
      message.error("Please provide a valid email")
    }
  };


  const verifyPasswordHandler = async () => {
    verifyingOtpStatus.setLoading(true)
    const res = await verifyOtp(email, otp);
    verifyingOtpStatus.setLoading(false)
    if (typeof res === "string") {
      toastSuccess("Otp verified");
      setOtpVerified(true);
      setToken(res)
    } else {
      toastError(res.errorMsg);
    }
  };

  return (
    <form action="" className="space-y-3">

      <Box>
        <TextField
          fullWidth
          type="email"
          label="Email"
          variant="outlined"
          placeholder="Enter your email address"
          style={{ borderColor: "#0865B6" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button
          onClick={sendOtpHandler}
          fullWidth
          variant="contained"
          style={{ background: "#0865B6", marginTop: "1rem" }}
          disabled={sendingOtpStatus.loading}
        >
          {sendingOtpStatus.loading ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "Send otp"}
        </Button>
      </Box>

      <Box >
        <TextField
          fullWidth
          type="number"
          label="OTP"
          placeholder="Enter OTP to verify"
          style={{ borderColor: "#0865B6" }}
          onChange={(e) => setOtp(e.target.value)}
          value={otp}
        />

        <Button
          onClick={verifyPasswordHandler}
          fullWidth
          variant="contained"
          style={{ background: "#0865B6", marginTop: "1rem" }}
          disabled={verifyingOtpStatus.loading}
        >
          {verifyingOtpStatus.loading ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "Verify otp"}
        </Button>
      </Box>
    </form>
  );
}
