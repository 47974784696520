import axios from "axios";
import { handleException } from "../utils/dataFetchHelper";
import Cookies from "universal-cookie";
import HttpResponse from "../model/HttpResponseModel";
import { API } from "../utils/helperObj";

const cookies = new Cookies();

export async function login(email, password) {
  try {
    const res = await axios.post(API.login, {
      email,
      password,
    });
    localStorage.setItem("user", res.data.user.id.toString());
    cookies.set("token", res.data.user.token);
    localStorage.setItem("user", JSON.stringify(res.data.user.profile));
    return new HttpResponse({
      statusCode: res.status,
      successMsg: "Login successful",
      data: res.data
    });
  } catch (err) {
    return handleException(err);
  }
}

export async function signUp(email, password) {
  try {
    const res = await axios.post(API.signUp, { email, password });
    cookies.set("token", res.data.token);
    return new HttpResponse({
      statusCode: res.status,
      successMsg: "Signup successful",
    });
  } catch (err) {
    return handleException(err);
  }
}


export async function sendEmailVerificationOtp(email) {
  try {
    const res = await axios.post(API.sendEmailVerificationOtp, {
      email,
    });
    cookies.set("token", res.data.token);
    return new HttpResponse({
      statusCode: res.status,
      successMsg: "An OTP is sent to your email",
    });
  } catch (err) {
    return handleException(err);
  }
}

export async function resentEmailVerificationOtp() {
  try {
    const res = await axios.get(API.resendEmailVerificationOtp, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    });
    return new HttpResponse({
      statusCode: res.status,
      successMsg: "OTP is sent to your email",
    });
  } catch (err) {
    return handleException(err);
  }
}


export async function verifyEmail(otp) {
  try {
    const res = await axios.post(
      API.emailVerification,
      {
        otp,
      },
      {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      }
    );
    cookies.set("token", res.data.user.token);
    return new HttpResponse({
      statusCode: res.status,
      successMsg: res.data.message,
    });
  } catch (err) {
    return handleException(err);
  }
}

/**
 *
 * @returns {Promise<boolean|HttpResponse>}
 *
 */
export async function checkUserVerified() {
  try {
    const res = await axios.get(API.checkUserVerified, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
    });
    return res.data.verification_status;
  } catch (err) {
    console.log(err.response.data);
    return handleException(err);
  }
}
