import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin,
} from "reactjs-social-login";
import styles from "./social_logins.module.scss";
import { HTTPStatus } from "../../utils/helperObj";
import { useNavigate } from "react-router";
import { toastError } from "../../utils/Notification";
import { socialLogin } from "../../controller/SocialLoginController";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import { FACEBOOK, GOOGLE, LINKEDIN } from "../../constants/socialLogin";

import google from "./images/google.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";

export function SocialLogins() {
  const navigate = useNavigate();
  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN.APP_ID,
    redirectUri: LINKEDIN.REDIRECT_URI,
    scope: LINKEDIN.SCOPE,
    onSuccess: (code) => handleLinkedinLogin(code),
    onError: (error) => {
      
    },
  });

  async function handleLogin(r, type) {
    const access_token = r.data?.access_token;
    if (!access_token) throw new Error("Can't extract access_token", r);

    let res = null;
    if (type === "google") res = await socialLogin("google", access_token);
    if (type === "facebook") res = await socialLogin("facebook", access_token);

    if (res && res.statusCode !== HTTPStatus.SUCCESS) {
      return toastError(res.errorMsg);
    } else {
      if (res.data.user?.personal_info === true) navigate("/dashboard");
      else navigate("/profile/personal");
    }
  }

  async function handleLinkedinLogin(code) {
    const res = await socialLogin("linkedin", code);
    if (res && res.statusCode !== HTTPStatus.SUCCESS) {
      return toastError(res.errorMsg);
    } else {
      if (res.data.user?.personal_info === true) navigate("/dashboard");
      else navigate("/profile/personal");
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <LoginSocialGoogle
          client_id={GOOGLE.WEB_CLIENT_ID}
          onResolve={(res) => handleLogin(res, "google")}
        >
          <img src={google} className={styles.icon} alt="google.png" />
        </LoginSocialGoogle>
      </div>
      <div>
        <LoginSocialFacebook
          appId={FACEBOOK.CLIENT_ID}
          onResolve={(res) => handleLogin(res, "facebook")}
        >
          <img src={facebook} className={styles.icon} alt="facebook.png" />
        </LoginSocialFacebook>
      </div>
      <div>
        <div onClick={linkedInLogin}>
          <img src={linkedin} className={styles.icon} alt="linkedin.png" />
        </div>
      </div>
    </div>
  );
}
