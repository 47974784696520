import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, InputLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../../components/consts";
import pdf_img from "../Resume/Pdf.png";

import { MobileDatePicker } from "@mui/lab";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { isValidCertificate } from "../../../../helpers/validate_certificate";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const CertificateUpdate = ({ id }) => {
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [variable, setVariable] = useState({
    cource_name: "",
    college_name: "",
    degree_status: "",
    education_mode: "",
    start_year: null,
    end_year: null,
    city: "",
    country: "",
    certificate: [],
    certificate_url: "",
  });

  const [courceName, setCourceName] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [degreeStatus, setDegreeStatus] = useState("");
  const [educationMode, setEducationMode] = useState("");
  const [start_year, setStartYear] = useState(null);
  const [end_year, setEndYear] = useState(null);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [certificate, setCertificate] = useState([]);
  const [certificate_name, setCertificate_name] = useState("");
  const [certificateUrl, setCertificateUrl] = useState("");

  const startDate = moment(start_year).format("YYYY-MM-DD");
  const endDate = moment(end_year).format("YYYY-MM-DD");

  const CertificateID = localStorage.getItem("CertificateID");

  useEffect(() => {
    axios
      .get(`${base_url}/profile/certificate/${CertificateID}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {

        setCourceName(res.data[0].degree_name);
        setCollegeName(res.data[0].university_name);
        setDegreeStatus(res.data[0].degree_status);
        setEducationMode(res.data[0].education_mode);
        setStartYear(res.data[0].start_year);
        setEndYear(
          res.data[0].end_year === "Invalid date" ? null : res.data[0].end_year
        );
        setCity(res.data[0].city);
        setCountry(res.data[0].country);
        setCertificate(res.data[0].certificate);
        setCertificate_name(res.data[0].certificate_name);
      })
      .catch((err) => {

      });
  }, []);

  const updateCertificate = () => {
    let formField = new FormData();

    const data = {
      degree_name: courceName,
      university_name: collegeName,
      degree_status: degreeStatus,
      start_year: startDate,
      end_year: endDate,
      city: city,
      country: country,
      is_completed: degreeStatus === "Completed" ? "true" : "false",
      education_mode: educationMode,
      certificate_url: certificateUrl,
    };

    const error = isValidCertificate(data);
    if (error) return toast.error(error);


    // Convert the data object into the FormData
    Object.entries(data).forEach(([key, value]) => {
      formField.append(key, value);
    });

    certificate.slice(0, 5) !== "https" &&
      formField.append("certificate", certificate);

    if (degreeStatus === "On-Going") {
      axios
        .put(
          `${base_url}/profile/certificate/update/${CertificateID}`,
          formField,
          {
            headers: {
              Authorization: "Token " + cookies.get("token"),
            },
          }
        )
        .then((res) => {

          navigate("/profile/professional-details/certificate");
          window.location.reload();

          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#1ab394", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#BAE8DE" },
          });
        })
        .catch((err) => {

          toast.error(`Error: ${err.response.data.errors.certificate}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            style: { backgroundColor: "#FF6347", color: "white" },
            icon: false,
            progressStyle: { backgroundColor: "#FFB1A3" },
          });
        });
    } else if (degreeStatus === "Completed") {
      if (startDate < endDate) {
        axios
          .put(
            `${base_url}/profile/certificate/update/${CertificateID}`,
            formField,
            {
              headers: {
                Authorization: "Token " + cookies.get("token"),
              },
            }
          )
          .then((res) => {

            navigate("/profile/professional-details/certificate");
            window.location.reload();

            toast.success("Updated Successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              style: { backgroundColor: "#1ab394", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#BAE8DE" },
            });
          })
          .catch((err) => {

            toast.error(`Error: ${err.response.data.errors.certificate}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              style: { backgroundColor: "#FF6347", color: "white" },
              icon: false,
              progressStyle: { backgroundColor: "#FFB1A3" },
            });
          });
      } else {
        toast.error("End date cannot be before Start date", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      }
    }
  };

  const deleteCertificate = () => {
    axios
      .delete(`${base_url}/profile/certificate/delete/${CertificateID}`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {

        navigate("/profile/professional-details/certificate");

        toast.success("Deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
      })
      .catch((err) => {

        toast.error("Error Deleting", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };

  return (
    <div>
      <div className="card text-dark bg-white my-4 border-none rounded-lg">
        <div className="card-body">
          <div className="card-text">
            <div className="grid grid-cols-1 md:grid-cols-2 space-y-3 md:space-y-0">
              <p className="text-xl font-bold text-[#0865B6]">
                Edit Certificate
              </p>
              <div className="text-left md:text-right space-x-5">
                <Button
                  className="text-base bg-[] rounded-lg px-3 py-1 font-semibold text-[#0865B6]"
                  onClick={handleOpen}
                  style={{
                    background: "#F1F1F1",
                    color: "#0865B6",
                    fontWeight: "bold",
                  }}
                >
                  <span>
                    <DeleteIcon />
                  </span>{" "}
                  Delete
                </Button>
              </div>
            </div>

            <div className="m-2 pt-4 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    fullWidth
                    label="Your Degree/ Course Name"
                    value={courceName}
                    onChange={(e) => {
                      setVariable({ ...variable, cource_name: e.target.value });
                      setCourceName(e.target.value);
                    }}
                    type="text"
                  // placeholder="Enter your Course Name"
                  // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="University/ College/ School Name"
                    value={collegeName}
                    onChange={(e) => {
                      setVariable({
                        ...variable,
                        college_name: e.target.value,
                      });
                      setCollegeName(e.target.value);
                    }}
                    type="text"
                  // placeholder="Enter Your University/College/School Name"
                  // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-customized-select-label">
                      Degree/ Course Status
                    </InputLabel>
                    <Select
                      label="Degree/ Course Status"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={degreeStatus}
                      onChange={(e) =>
                        // setVariable({
                        //   ...variable,
                        //   degree_status: e.target.value,
                        // })
                        setDegreeStatus(e.target.value)
                      }
                    >
                      <MenuItem value={"On-Going"}>On-Going</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-customized-select-label">
                      Education Mode
                    </InputLabel>
                    <Select
                      label="Education Mode"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={educationMode}
                      onChange={(e) =>
                        // setVariable({
                        //   ...variable,
                        //   education_mode: e.target.value,
                        // })
                        setEducationMode(e.target.value)
                      }
                    >
                      <MenuItem value={"Online"}>Online</MenuItem>
                      <MenuItem value={"Offline"}>Offline</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    fullWidth
                    label="City"
                    value={city}
                    onChange={(e) => {
                      setVariable({ ...variable, city: e.target.value });
                      setCity(e.target.value);
                    }}
                    type="text"
                  // placeholder="Please select your country"
                  // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Country"
                    value={country}
                    onChange={(e) => {
                      setVariable({ ...variable, country: e.target.value });
                      setCountry(e.target.value);
                    }}
                    type="text"
                  // placeholder="Please select your country"
                  // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <TextField
                    fullWidth
                    label="Certificate URL"
                    value={certificateUrl}
                    onChange={(e) =>
                      // setVariable({
                      //   ...variable,
                      //   certificate_url: e.target.value,
                      // })
                      setCertificateUrl(e.target.value)
                    }
                  // type="text"
                  // className="outline-none border-gray-500 border-[1px] rounded-md p-2"
                  // placeholder="Link should be in public mode"
                  />
                </div>
                <div className="flex flex-col">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      disableFuture
                      label="Start Date"
                      value={start_year}
                      openTo="day"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setVariable({ ...variable, start_year: newValue });
                        setStartYear(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex flex-col">
                  {degreeStatus === "Completed" && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        disableFuture
                        label="End Date"
                        value={end_year}
                        openTo="day"
                        views={["year", "month", "day"]}
                        onChange={(newValue) => {
                          setVariable({ ...variable, end_year: newValue });
                          setEndYear(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-1">
                <span className="font-semibold text-slate-500">
                  Upload Certificate Document
                </span>
                <div className="items-center space-x-5 space-y-3 grid grid-cols-1 md:space-y-0 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                  <div
                    className="card border-none"
                    style={{ width: "15rem", backgroundColor: "#F3F3F3" }}
                    align="center"
                  >
                    <div className="cardbody">
                      <div className="cardtext">
                        <div className="grid grid-cols-4">
                          <div className="col-span-4" align="center">
                            <img
                              className="p-2 w-24 md:w-28"
                              src={pdf_img}
                              alt=""
                            />
                          </div>
                          {/*<div*/}
                          {/*    className="col"*/}
                          {/*    style={{color: "rgb(8, 101, 182)"}}*/}
                          {/*>*/}
                          {/*    <DeleteIcon*/}
                          {/*        className="m-2"*/}
                          {/*        fontSize="small"*/}
                          {/*        style={{color: "#157EFB"}}*/}
                          {/*    />*/}
                          {/*</div>*/}
                        </div>
                        <div
                          className="bg-[#e5e5e5] text-[#157EFB]"
                          id="PDF_filename_div"
                        >
                          <span className="font-semibold">File name: </span>
                          {certificate?.slice(0, 5) === "https"
                            ? certificate.substring(
                              certificate.lastIndexOf("/") + 1,
                              certificate.indexOf("?")
                            )
                            : "please select a file" ||
                              certificate?.slice(0, 5) !== "https"
                              ? certificate?.name
                              : "please select a file"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <TextField
                      type="file"
                      id="PDF_input"
                      style={{ display: "none" }}
                      onChange={(e) => setCertificate(e.target.files[0])}
                    />
                    <label
                      htmlFor="PDF_input"
                      className="text-[#157EFB] font-bold w-44 text-center text-lg bg-[#F1F1F1] p-2 rounded-lg cursor-pointer"
                    >
                      <DriveFolderUploadIcon className="mr-2" />
                      Re - Upload
                    </label>
                    {/*<Button*/}
                    {/*    className="text-lg p-2 w-44 cursor-pointer"*/}
                    {/*    style={{*/}
                    {/*        background: "#F1F1F1",*/}
                    {/*        color: "#A6A6A6",*/}
                    {/*        fontWeight: "bold",*/}
                    {/*        borderRadius: "0.5rem"*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Remove*/}
                    {/*</Button>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 space-x-5">
              <Button
                className={`text-lg px-4 py-2 duration-300 ${courceName === "" ||
                    collegeName === "" ||
                    degreeStatus === "" ||
                    city === "" ||
                    country === "" ||
                    start_year === "" ||
                    educationMode === ""
                    ? "opacity-50"
                    : "opacity-100"
                  }`}
                disabled={
                  courceName === "" ||
                    collegeName === "" ||
                    degreeStatus === "" ||
                    city === "" ||
                    country === "" ||
                    start_year === "" ||
                    educationMode === ""
                    ? true
                    : false
                }
                onClick={updateCertificate}
                style={{
                  background: "#F1F1F1",
                  color: "#0865B6",
                  fontWeight: "bold",
                  borderRadius: "0.5rem",
                  width: "7rem",
                }}
              >
                <span className="mr-1">
                  <DoneAllIcon style={{ fontSize: "large" }} />
                </span>{" "}
                Save
              </Button>
              <Button
                className={`text-lg px-4 py-2 duration-300 `}
                onClick={() =>
                  navigate("/profile/professional-details/certificate")
                }
                style={{
                  background: "#F1F1F1",
                  color: "#0865B6",
                  fontWeight: "bold",
                  borderRadius: "0.5rem",
                  width: "7rem",
                }}
              >
                <span className="mr-1">
                  <CloseIcon style={{ fontSize: "large" }} />
                </span>{" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="w-3/4 md:w-1/3">
            <Typography
              id="transition-modal-title"
              className="text-lg md:text-xl"
            >
              Confirm Deleting the experience?? Please confirm
            </Typography>
            <Typography className="mt-2">
              <button
                className="bg-red-500 hover:bg-red-300 duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full"
                onClick={deleteCertificate}
              >
                Yes
              </button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CertificateUpdate;
