import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import GithubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Typography
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { base_url } from "../../../components/consts";

import { grey } from "@mui/material/colors";
import moment from "moment";
import Cookies from "universal-cookie";
import RichDescription from "../../../components/jobDescription/RichDescription";

const cookies = new Cookies();

const PersonalDetails = () => {
  const navigate = useNavigate();
  //Getting Details to show
  const [info, setInfo] = useState([]);

  const LoadInfo = async () => {
    axios
      .get(`${base_url}/profile/personal`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((res) => {
        setInfo(res.data);

      });
  };

  useEffect(() => {
    LoadInfo();
  }, []);



  return (
    <Container maxWidth="md" sx={{ marginTop: "4rem", marginBottom: "4rem" }}>
      <div>
        {info.length ? (
          info.map((detail) => (
            <Grid
              container
              columns={{ md: 12, xs: 6 }}
              key={detail.id}
              spacing={5}
            >
              <Grid item md={6} xs={3}>
                <Box
                  display="flex"
                  flexDirection={{
                    md: "row",
                    xs: "column",
                  }}
                  alignItems={{
                    md: "center",
                    xs: "flex-start",
                  }}
                >
                  <Avatar
                    src={detail.profile_pic}
                    alt="profile"
                    sx={{ width: "60px", height: "60px" }}
                  />
                  <Typography
                    variant="h5"
                    ml="10px"
                    mt={{
                      md: "0px",
                      xs: "10px",
                    }}
                    fontSize={{
                      md: "25px",
                      xs: "22px",
                    }}
                  >
                    {`${detail.first_name} ${detail.last_name}`}
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={6} xs={3}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => navigate("/profile/personal/form/update")}
                    style={{
                      backgroundColor: "#F1F1F1",
                      borderRadius: "10px",
                      color: "rgb(8, 101, 182)",
                    }}
                  >
                    Edit <EditIcon style={{ fontSize: "medium" }} />
                  </Button>
                </Box>
              </Grid>
              <Grid item md={4} xs={3} sx={{ textAlign: "left" }}>
                <Typography>Email ID</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.email}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "center",
                    xs: "right",
                  },
                }}
              >
                <Typography>Designation</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.designation}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "right",
                    xs: "left",
                  },
                }}
              >
                <Typography>Contact</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.mobile_no}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "left",
                    xs: "right",
                  },
                }}
              >
                <Typography>Matrial Status</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.marital_status}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "center",
                    xs: "left",
                  },
                }}
              >
                <Typography>Aadhar Card Number</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.aadhaar_card ? detail.aadhaar_card : "NA"}
                </Typography>
              </Grid>

              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "right",
                    xs: "right",
                  },
                }}
              >
                <Typography>Pan Card Number</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.pan_card ? detail.pan_card  : "NA" }
                </Typography>
              </Grid>

              <Grid item md={4} xs={3} sx={{ textAlign: "left" }}>
                <Typography>Date of birth</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {moment(detail.dob).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "center",
                    xs: "right",
                  },
                }}
              >
                <Typography>Gender</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.gender}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "right",
                    xs: "left",
                  },
                }}
              >
                <Typography>Language</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.language}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "left",
                    xs: "right",
                  },
                }}
              >
                <Typography>Total Experiance</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.total_exp} Years
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "center",
                    xs: "left",
                  },
                }}
              >
                <Typography>Guardian name</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.guardian_name}
                </Typography>
              </Grid>

              <Grid
                item
                md={4}
                xs={3}
                sx={{
                  textAlign: {
                    md: "right",
                    xs: "right",
                  },
                }}
              >
                <Typography>Guardian number</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.guardian_phn_no}
                </Typography>
              </Grid>



              <Grid item md={12} xs={6}>
                <Typography>Current Address</Typography>
                <Typography fontSize={20} fontWeight="bold">
                  {detail.current_address}
                </Typography>
              </Grid>

              <Grid item md={12} xs={6}>
                <Typography>About you</Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  variant="body1"
                  sx={{ wordBreak: "break-all" }}
                >
                  <RichDescription>{detail.about_you}</RichDescription>
                </Typography>
              </Grid>
              {detail.linkedin_id !== null && detail.linkedin_id !== "" ? (
                <Grid item md={2} xs={2}>
                  <a
                    href={`${detail.linkedin_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      endIcon={<LinkedInIcon sx={{ color: "#FFFFFF" }} />}
                    >
                      LinkedIn
                    </Button>
                  </a>
                </Grid>
              ) : (
                <></>
              )}

              {detail.github_id !== null && detail.github_id !== "" ? (
                <Grid item md={2} xs={2}>
                  <a
                    href={`${detail.github_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: grey[900] }}
                      endIcon={<GithubIcon sx={{ color: "#FFFFFF" }} />}
                    >
                      GIthub
                    </Button>
                  </a>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          ))
        ) : (
          <div className="h-[30vh]">
            <p className="my-4 mx-3 text-2xl font-bold text-[#0865B6]">
              Add Your Personal Details
            </p>
            <Button
              variant="outlined"
              endIcon={<AddCircleOutlineIcon style={{ fontSize: "large" }} />}
              onClick={(e) => navigate("/profile/personal/form/add")}
            >
              Add Details
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default PersonalDetails;
