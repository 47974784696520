import React, {useEffect, useState} from "react";
import axios from "axios";
import {styled} from "@mui/material/styles";
import {Slider} from "@mui/material";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const Template3 = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [designation, setDesignation] = useState("");
    const [profilePic, setProfilePic] = useState("");

    const [personalDetails, setPersonalDetails] = useState([]);
    const [workExperience, setWorkExperience] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [skills, setSkills] = useState([]);
    const [certificates, setCertificates] = useState([]);

    const getPersonalDetails = () => {
        axios
            .get(
                `profile/personal`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setPersonalDetails(res.data);
                setFirstName(res.data[0].first_name);
                setLastName(res.data[0].last_name);
                setDesignation(res.data[0].designation);
                setProfilePic(res.data[0].profile_pic);
            })
            .catch((err) => {
                
            })
    }

    const getEducationData = () => {
        axios
            .get(
                `profile/education-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setEducationData(res.data);
            })
            .catch((err) => {
                
            })
    }

    const getWorkExperience = () => {
        axios
            .get(
                `profile/workExperience`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setWorkExperience(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getSkills = () => {
        axios
            .get(
                "/profile/skills",
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setSkills(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getCertificate = () => {
        axios
            .get(
                `profile/certificate`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setCertificates(res.data);
            })
            .catch((err) => {
                
            });
    }

    const SkillSlider = styled(Slider)({
        color: "white",
        height: 20,
        border: "none",
        "& .MuiSlider-track": {
            backgroundColor: "white"
        },
        "& .MuiSlider-thumb": {
            display: "none"
        }
    });

    useEffect(() => {
        getPersonalDetails();
        getEducationData();
        getWorkExperience();
        getSkills();
        getCertificate();
    }, []);


    return (
        <div className="font-sans">

            <div className="flex flex-row">


                <div className="bg-[#323b4c] text-white pb-5 w-2/5">
                    <div align="center" className="h-[200px]">
                        <img src={profilePic} className=" h-36 w-36 mt-5 rounded-full" alt=""/>
                    </div>

                    <div className="mb-5">
                        <p className="px-5 text-xl font-bold">
                            Contact
                        </p>
                        <div className="border-1 ml-12 border-white mb-4"/>

                        {personalDetails.map((personal) => (
                            <div key={personal} className="px-5 space-y-5">
                                <div>
                                    <p>
                                        Phone
                                    </p>
                                    <p className="text-sm text-neutral-400">
                                        {personal.mobile_no}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        Email
                                    </p>
                                    <p className="text-sm text-neutral-400">
                                        {personal.email}
                                    </p>
                                </div>

                                <div className="break-all">
                                    <p>
                                        Address
                                    </p>
                                    <p className="text-sm text-neutral-400">
                                        {personal.present_address}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5">
                        <p className="px-5 text-xl font-bold">
                            Education
                        </p>
                        <div className="border-1 ml-12 border-white mb-4"/>

                        {educationData.map((eduData) => (
                            <div key={eduData} className="px-5">
                                <p>
                                    {eduData.course}
                                </p>
                                <p className="break-words text-sm text-neutral-400">
                                    {eduData.university_name}
                                </p>
                                <p className="text-sm text-neutral-400">
                                    {eduData.pass_year}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className="mb-5">
                        <p className="px-5 text-xl font-bold">
                            Skills
                        </p>
                        <div className="border-1 ml-12 border-white mb-4"/>

                        {skills.map((skillData) => (
                            <div key={skillData.id} className="px-5">
                                <p className="text-base text-[#98989C] break-all">{skillData.skills}</p>
                                <SkillSlider value={skillData.rating * 10}/>
                                {/*<input*/}
                                {/*    type="range"*/}
                                {/*    value={skillData.rating * 10}*/}
                                {/*    readOnly*/}
                                {/*    style={{*/}
                                {/*        borderRadius: "20px",*/}
                                {/*        height: "12px",*/}
                                {/*        marginTop: "10px",*/}
                                {/*        width: "100%",*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </div>
                        ))}
                    </div>
                </div>

                <div>

                    <div className="h-[200px] px-5 mt-5">
                        <p className="text-[#323b4c] text-2xl font-bold">
                            {firstName} {lastName}
                        </p>
                        <p className="text-[#323b4c]">
                            {designation}
                        </p>
                        <p className="text-xs text-justify break-words">
                            Your profile is one of the most important parts of your resume.
                            this section should give a concise summary of your career to date (5 sentences max).
                            this will be one of the first section your future employee/recruiter will see, and you
                            don't want to be the only one!
                            Your profile needs to capture their attention and get them to read your whole CV.
                            One final tip, it is usually easy to write this part last.

                        </p>
                    </div>
                    <div>
                        <p className="px-5 text-[#323b4c] text-xl font-bold">
                            Work Experience
                        </p>
                        <div className="border-1 ml-12 border-[#323b4c] mb-4"/>
                        {workExperience.map((workExData) => (
                            <div key={workExData.id} className="px-5">
                                <Timeline>
                                    <TimelineItem className="before:hidden">
                                        <TimelineSeparator>
                                            <TimelineDot sx={{background:"#323b4c"}}/>
                                            <TimelineConnector sx={{background:"#323b4c"}}/>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <p>
                                                {workExData.start_date} - {workExData.job_status === "On-Going" ? workExData.job_status : workExData.end_date}
                                            </p>
                                            <p>
                                                {workExData.company_name} | {workExData.city}, {workExData.country}
                                            </p>
                                            <p className="font-semibold">
                                                {workExData.position}
                                            </p>
                                            <p className="break-all">
                                                {workExData.description}
                                            </p>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Template3;