import React, { useEffect } from "react";
import useCheckUserVerified from "../hooks/guard/useCheckUserVerified";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { Box } from "@mui/material";

/**
 * @typedef {object} Props
 * @property {JSX.Element} Component
 */

/**
 * @param {Props} props
 */
export default function VerifiedRoute({ Component }) {
  const checking = useCheckUserVerified();
  const userVerified = useSelector((state) => state.userVerified.userVerified);
  const navigate = useNavigate();


  useEffect(() => {
    if (userVerified === false) {
      navigate("/email-verification");
    }
  }, [navigate, userVerified])


  return (
    <>
      {checking ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size="2rem" />
        </Box>
      ) : (
        <Component />
      )}
    </>
  );
}
