import { createSlice } from "@reduxjs/toolkit";

/**
 * @typedef {object} State
 * @property {boolean | null} userVerified
 * @property {boolean} loggedIn
 */

/**
 * @type {State}
 */
const initialState = {
  userVerified: null,
  loggedIn: false,
};

const userVerfifiedSlice = createSlice({
  name: "userVerified",
  initialState,
  reducers: {
    setUserVerified: (state, action) => {
      state.userVerified = action.payload;
      state.loggedIn = true;
    },
    resetUserVerificationStatus: () => initialState,
  },
});

export default userVerfifiedSlice.reducer;
export const { setUserVerified, resetUserVerificationStatus } =
  userVerfifiedSlice.actions;
