import React from "react";
import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Applications from "./Pages/Applications/Applications";
import Calender from "./Pages/Calender/Calender";
import Help from "./Pages/Help/Help";
import Home from "./Pages/Home/Dashboard";
import LandingPage from "./Pages/Landing Page/LandingPage";
import Message from "./Pages/Messages/Message";
import SendMessage from "./Pages/Messages/SendMessage";
import SendReply from "./Pages/Messages/SendReply";
import Profile from "./Pages/My Profile/Profile/Profile";
import ScheduleMeets from "./Pages/Schedule Meets/ScheduleMeets";
import Search from "./Pages/Search/Search";
import Login from "./Pages/authentication/Login";

import Cookies from "universal-cookie";
import InterviewReview from "./Pages/Landing Page/InterviewReview";
import JobDetail from "./Pages/Landing Page/JobDetail";
import AddInformation from "./Pages/My Profile/Personal Details/AddInformation";
import EmailVerification from "./Pages/authentication/EmailVerification";
import ForgotPassword from "./Pages/authentication/ForgotPassword";
import SignUp from "./Pages/authentication/SignUp";
import VerifiedRoute from "./components/VerifiedRoute";

import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ActiveJobs from "./Pages/Applications/ActiveJobs";
import Servey from "./Pages/servey/Servey";
import Webinar from "./Pages/webinar/Webinar";
import WebinarDetails from "./Pages/webinar/WebinarDetails";
import Error404 from "./images/404.png";
import ProfileForm from "./Pages/user/ProfileForm";
import NewMessage from "./Pages/Messages/Messages";

const cookies = new Cookies();

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="" element={<LandingPage />} />
          <Route
            path="/job-details/:id"
            element={<VerifiedRoute Component={JobDetail} />}
          />
          <Route path="/servey/:assignmentId" element={<VerifiedRoute Component={Servey} />} />
          <Route
            path="/interview-review-form/:id"
            element={<VerifiedRoute Component={InterviewReview} />}
          />
          <Route path="/pages/not-found" element={<Page404 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login/linkedin" element={<LinkedInCallback />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/profile/*"
            element={<VerifiedRoute Component={Profile} />}
          />
          <Route
            path="/dashboard/*"
            element={<VerifiedRoute Component={Home} />}
          />
          <Route
            exact
            path="/applications"
            element={<Navigate replace to={"/applications/jobs"} />}
          />
          <Route
            path="/applications/*"
            element={<VerifiedRoute Component={Applications} />}
          />
          <Route
            path="/schedule_meets/*"
            element={<VerifiedRoute Component={ScheduleMeets} />}
          />
          <Route
            exact
            path="/schedule_meets"
            element={<Navigate replace to={"/schedule_meets/upcoming"} />}
          />
          {/* <Route
            exact
            path="/message"
            element={<VerifiedRoute Component={Message} />}
          /> */}
          <Route
            exact
            path="/webinar/:type"
            element={<VerifiedRoute Component={Webinar} />}
          />
          <Route
            exact
            path="/webinar/:type/:id"
            element={<VerifiedRoute Component={WebinarDetails} />}
          />
          <Route
            exact
            path="/send-message"
            element={<VerifiedRoute Component={SendMessage} />}
          />
          <Route
            exact
            path="/send-reply"
            element={<VerifiedRoute Component={SendReply} />}
          />
          <Route
            exact
            path="/calendar"
            element={<VerifiedRoute Component={Calender} />}
          />
          <Route
            exact
            path="/search"
            element={<Search />}
          />
          {/* <Route
            exact
            path="/contact"
            element={<VerifiedRoute Component={Contact} />}
          /> */}
          <Route
            path="/profile/personal/add"
            element={<VerifiedRoute Component={AddInformation} />}
          />
          <Route path="/applications/active-job/:id" element={<ActiveJobs />} />
          ''
          {/* type === "add" || "update". add ===  adding new profile details and update === updating existing profile details  */}
          <Route path="/profile/personal/form/:type" element={<ProfileForm />} />

          <Route path="/messages/:messageType" element={<NewMessage />} />
          {cookies.get("token") ? (
            <Route
              exact
              path="/"
              element={<Navigate replace to="/profile" />}
            />
          ) : (
            <Route
              exact
              path="/"
              element={<Navigate replace to="/landingpage" />}
            />
          )}
          {/*<Route path="/messages/*" element={<Messages />} />*/}
          {/* <Route path="/messages/send-message/*" element={<SendMessage />} />*/}
          {/*<Route path="/messages/message-details/*" element={<MessageDetails />} />*/}

          <Route path="/help" element={<Help />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

export function Page404() {
  return (
    <div className="grid place-items-center">
      <div className="justify-center">
        <div className="flex justify-center items-center mt-11">
          <div className="flex flex-col justify-center items-center border-1 bg-white w-[350px] p-10 rounded-md">
            <img src={Error404} alt="Errr 404" />
          </div>
        </div>
        <div className="flex flex-col items-center my-[10vh]">
          <div className="font-bold text-[#1E66B1] text-lg sm:text-xl md:text-2xl">
            Here are the Links to find the route for you
          </div>
          <div className="flex flex-wrap gap-[.5rem] my-10 md:gap-4 justify-around">
            <Link
              to="/"
              className="no-underline p-2 rounded-lg hover:text-white text-xl text-gray-100 font-bold bg-[#1E66B1] "
            >
              Home
            </Link>
            <Link
              to="/login"
              className="no-underline p-2 rounded-lg text-xl hover:text-white text-gray-100 font-bold  bg-[#1E66B1] "
            >
              Sign up / Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}