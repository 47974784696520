import axios from "axios";
import HttpResponse from "../model/HttpResponseModel";
import BusinessData from "../model/BusinessModel";
import { API } from "../utils/helperObj";

export async function getBusinessInformation() {
  try {
    const res = await axios.get(API.businessInfo);
    const businessData = BusinessData.createDataObj(res.data.business_details[0]);
    return businessData;
  } catch (err) {
    return new HttpResponse({
      statusCode: err.response.status,
      successMsg: err.response.statusText,
    });
  }
}
