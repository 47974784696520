import React from "react";
import useBusinessInfo from "../../hooks/useBusinessInfo";
import homeImage from "../../images/HRI-home.png";
import { useSelector } from "react-redux";

/**
 * 
 * @typedef {object} Props
 * @property {JSX.Element} children
 * @property {string} heading
 */

/**
 * @param {Props} props
 */

export default function AuthPageLayout({ heading, children }) {
  const fetchingBusinessInfo = useBusinessInfo();

  const { companyName, tagLine, termsLink, privacyPolicyLink } = useSelector(
    (state) => state.businessInfo.info
  );

  if (fetchingBusinessInfo) {
    return <div>Loading...</div>;
  }
  return (
    <div className="h-screen grid place-items-center">
      <div className="grid grid-cols-1 md:grid-cols-2 m-6 login_row_div">
        <div className="login_col1 flex flex-col content-center">
          <div className="text-xl">
            <p className="font-bold">
              Welcome to <b className="text-[#0865B6]">{companyName}</b>
            </p>
          </div>
          <p className="text-gray-500 text-lg">{tagLine}</p>
          <div className="center overflow-hidden mx-10 w-full h-auto">
            <img src={homeImage} className="w-0 md:w-[65%] h-full" alt="" />
          </div>
        </div>

        <div className=" max-w-xl">
          <div className="cardbody p-6 md:px-8 md:py-4 lg:px-10 lg:py-6 border-3 rounded-xl border-[#0865B6]">
            <div className="cardtext">
              <div className="text-3xl mb-4 md:mb-6 lg:mb-10 md:text-3xl lg:text-5xl">
                {heading}
              </div>
              <div className="space-y-3 lg:space-y-4 xl:space-y-5">
                {children}

                <div className="md:pt-2">
                  <p align="center" style={{ color: "rgb(1,1,1,0.4)" }}>
                    The{" "}
                    <a
                      rel="noreferrer"
                      className="font-bold text-[#0865B6]"
                      target="_blank"
                      href={termsLink}
                    >
                      terms of use
                    </a>{" "}
                    and{" "}
                    <a
                      rel="noreferrer"
                      className="font-bold text-[#0865B6]"
                      target="_blank"
                      href={privacyPolicyLink}
                    >
                      our Policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
