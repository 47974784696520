import React, { useRef, useState } from "react";
import AuthPageLayout from "../../components/Layout/AuthPageLayout";
import VerifiedOTP from "../../components/forgotpassword/VerifiedOTP";
import ResetPassword from "../../components/forgotpassword/ResetPassword";

export default function ForgotPassword() {
  const [otpVerified, setOtpVerified] = useState(false);
  const [token, setToken] = useState("");


  if (!otpVerified) {
    return (
      <AuthPageLayout hedaing="Reset Password">
        <VerifiedOTP
          setOtpVerified={setOtpVerified}
          setToken={setToken}
        />
      </AuthPageLayout>
    );
  }

  if (otpVerified) {
    return (
      <AuthPageLayout hedaing="Reset Password">
        <ResetPassword token={token} />
      </AuthPageLayout>
    );
  }

  return <AuthPageLayout hedaing="Reset Password"></AuthPageLayout>;
}
