import axios from "axios";
import { API } from "../utils/helperObj";
import Cookies from "universal-cookie";
import { handleException } from "../utils/dataFetchHelper";
import HttpResponse from "../model/HttpResponseModel";
const cookies = new Cookies();

/**
 *
 * @param {object} profileData
 * @returns {Promise<[object|null, import("../model/HttpResponseModel").default | null]>}
 */
export async function getProfileDetails() {
    const result = [null, null];
    try {
        const res = await axios.get(API.profileDetails, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });

        const data = res.data[0];

        const resultObj = {
            firstName: data.first_name,
            lastName: data.last_name,
            emailId: data.email,
            profilePicLink: data.profile_pic,
            designation: data.designation,
            phoneNumber: data.mobile_no,
            maritalStatus: data.marital_status,
            aadharCardNumber: data.aadhaar_card,
            panCardNumber: data.pan_card,
            dateOfBirth: data.dob,
            currentAddress: data.current_address,
            parmanentAddress: data.address,
            gender: data.gender,
            language: data.language,
            totalExperience: data.total_exp,
            pinCode: data.pin,
            city: data.city,
            state: data.state,
            country: data.country,
            guardianName: data.guardian_name,
            guardianNumber: data.guardian_phn_no,
            aboutYou: data.about_you,
            linkedInLink: data.linkedin_id,
            githubLink: data.github_id,
        };

        result[0] = resultObj;
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

export async function updateProfileData(formData) {
    try {
        await axios.put(API.updateProfileDetails, formData, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        return new HttpResponse({
            statusCode: 200,
            successMsg: "Profile details updated",
        });
    } catch (err) {
        return handleException(err);
    }
}

export async function addProfileDetails(formData) {
    try {
        await axios.post(API.addProfileDetails, formData, {
            headers: {
                Authorization: "Token " + cookies.get("token"),
            },
        });
        return new HttpResponse({
            statusCode: 201,
            successMsg: "Profile details is added",
        });
    } catch (err) {
        return handleException(err);
    }
}
