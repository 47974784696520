import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { resetPasword } from "../../controller/ForgotPasswordController";
import { HTTPStatus } from "../../utils/helperObj";
import { useNavigate } from "react-router";
import { toastError, toastSuccess } from "../../utils/Notification";
import { message } from "antd";
import useLoading from "../../hooks/useLoading";

/**
 * @typedef {object} Params
 * @property {string} token
 */

/**
 * @param {Params} params
 */

export default function ResetPassword({ token }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const resetingPassword = useLoading();

  const handleResetPassword = async () => {
    console.log("token is ", token)
    if (password === confirmPassword) {
      resetingPassword.setLoading(true)
      const res = await resetPasword(password, token);
      resetingPassword.setLoading(false)

      if (res.statusCode === HTTPStatus.SUCCESS) {
        toastSuccess(res.successMsg);
        navigate("/login");
      } else {
        toastError(res.errorMsg);
      }
    } else {
      message.error("confirm password doesn't match");
    }
  };

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          Enter your new password
        </InputLabel>
        <OutlinedInput
          label="Enter your new password"
          // value={newPassword}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type={showPassword ? "text" : "password"}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          required
        />
      </FormControl>
      <FormControl sx={{ marginTop: "2rem", width: "100%" }}>
        <InputLabel htmlFor="outlined-adornment-password">
          Confirm password
        </InputLabel>
        <OutlinedInput
          label="Confirm password"
          // value={newPassword}
          type={showConfirmPassword ? "text" : "password"}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                type={showPassword ? "text" : "password"}
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          required
        />
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        style={{ background: "#0865B6" }}
        onClick={handleResetPassword}
        sx={{ marginTop: "10px" }}
        disabled={resetingPassword.loading}
      >
        {resetingPassword.loading ? <CircularProgress sx={{ color: "white" }} size="1.6rem" /> : "RESET password"}
      </Button>
    </>
  );
}
