import { ObjType } from "../utils/helperObj";

export default class BusinessData {
  type = ObjType.businessData;
  companyName;
  tagLine;
  termsLink;
  privacyPolicyLink;

  static createDataObj(data) {
    const obj = new BusinessData();
    obj.companyName = data.name;
    obj.tagLine = data.tag_line;
    obj.termsLink = data.terms;
    obj.privacyPolicyLink = data.privacy_policy;
    return obj;
  }

  static initialDataObj() {
    const obj = new BusinessData();
    obj.companyName = "";
    obj.tagLine = "";
    obj.termsLink = "";
    obj.privacyPolicyLink = "";
    return obj;
  }
}
