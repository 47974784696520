import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { base_url } from "../../../../components/consts";
import pdf_img from "../Resume/Pdf.png";
import Photo from "./file icon.jpeg";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    border: "none",
    // boxShadow: 24,
    p: 4,
};

const Document = () => {
    const cookies = new Cookies();

    const [url, setUrl] = useState("");
    const [document, setDocument] = useState([]);
    const [newDocument, setNewDocument] = useState([]);
    const [docId, setDocId] = useState("");
    const [other, setOther] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        axios
            .get(`${base_url}/profile/document`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {

                setDocument(res.data);
            })
            .catch((err) => {

            });
    }, []);

    const UploadDocument = () => {
        let formField = new FormData();

        formField.append("document", newDocument);

        axios
            .post(base_url + "/profile/document/add", formField, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((response) => {

                window.location.reload();
                toast.success("Sucessfully Uploaded Document", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch((err) => {

                toast.error("Error Uploading Document", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    const UploadOtherDocument = () => {
        let formField = new FormData();

        formField.append("document", newDocument);
        formField.append("is_other", true);

        axios
            .post(base_url + "/profile/document/add", formField, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((response) => {

                window.location.reload();
            })
            .catch((err) => {

                toast.error("Error Uploading Document", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    const DeleteDocument = () => {
        axios
            .delete(base_url + `/profile/document/delete/${docId}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((response) => {

                window.location.reload();
            })
            .catch((err) => {

                toast.error(`Error deleting Document, ${err.data}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    return (
        <div className="px-0 md:px-10 lg:px-16 cursor-default">
            <div className="card border-none rounded-lg bg-white mb-3 profile_card">
                <div className="card-text px-4 md:px-6 py-3">
                    <div
                        className="grid grid-cols-1 md:grid-cols-2 flex items-center justify-between mb-2">
                        <div>
                            <p className="font-bold text-[#0865B6] text-xl md:text-2xl">
                                Citizen IDs
                            </p>
                        </div>
                        {/* <button className="text-base bg-[#F1F1F1] rounded-lg px-3 py-1 font-semibold text-[#0865B6]">
                              + Upload New
                            </button> */}
                        <div className="text-left md:text-right">
                            <TextField
                                type="file"
                                id="PDF_input"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    setNewDocument(e.target.files[0]);
                                    handleOpen();
                                    setUrl(e.target.files[0].name);
                                }}
                            />
                            <label
                                htmlFor="PDF_input"
                                className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-1.5 rounded cursor-pointe"
                            >
                                <DriveFolderUploadIcon className="mr-2" />
                                Upload
                            </label>
                        </div>
                    </div>

                    <div
                        className="grid gap-4 items-center justify-start mt-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {document
                            .filter((type) => type.is_other === false)
                            .map((row) => (
                                <div
                                    className="card border-none mr-5"
                                    style={{ width: "15rem", backgroundColor: "#F3F3F3" }}
                                    align="center"
                                    key={row.id}
                                >
                                    <div className="cardbody">
                                        <div className="grid grid-cols-10 my-2">
                                            <div className="col-span-8" align="center">
                                                <a
                                                    href={row.document}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={Photo} className="w-20" />

                                                </a>
                                            </div>
                                            <div
                                                className="col-span-1"
                                                style={{ color: "rgb(8, 101, 182)" }}
                                                onClick={() => {
                                                    handleOpen();
                                                    setDocId(row.id);
                                                    setUrl(row.document_name);
                                                }}
                                            >
                                                <DeleteIcon
                                                    fontSize="medium"
                                                    style={{ color: "#157EFB", height: 40 }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="bg-[#e5e5e5] text-[#157EFB]"
                                            id="PDF_filename_div"
                                        >
                                            <span className="font-bold">File name: </span>
                                            <p className="px-3 pb-2">
                                                {row.document_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>

            <div className="card border-none rounded-lg bg-white mb-3 profile_card">
                <div className="card-body">
                    <div className="card-text px-2 md:px-6 ">
                        <div
                            className="grid grid-cols-1 md:grid-cols-2 flex items-center justify-between mb-2">
                            <div>
                                <p className="font-bold text-[#0865B6] text-xl md:text-2xl">
                                    Other Documents
                                </p>
                            </div>
                            {/* <button className="text-base bg-[#F1F1F1] rounded-lg px-3 py-1 font-semibold text-[#0865B6]">
                                    + Upload New
                            </button> */}
                            <div className="text-left md:text-right">
                                <TextField
                                    type="file"
                                    id="PDF_input"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        setNewDocument(e.target.files[0]);
                                        handleOpen();
                                        setUrl(e.target.files[0].name);
                                    }}
                                />
                                <label
                                    onClick={() => setOther(true)}
                                    htmlFor="PDF_input"
                                    className="text-[#0865B6] font-bold text-lg bg-[#F1F1F1] p-1.5 rounded cursor-pointe"
                                >
                                    <DriveFolderUploadIcon className="mr-2" />
                                    Upload
                                </label>
                            </div>
                        </div>

                        <div className="grid gap-4 items-center justify-start mt-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {document
                                .filter((type) => type.is_other === true)
                                .map((row) => (
                                    <div
                                        className="card border-none"
                                        style={{ width: "15rem", backgroundColor: "#F3F3F3" }}
                                        align="center"
                                        key={row.id}
                                    >
                                        <div className="cardbody">
                                            <div className="cardtext">
                                                <div className="grid grid-cols-10 my-2">
                                                    <div className="col-span-8" align="center">
                                                        <a
                                                            href={row.document}
                                                            download
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img className="my-1" src={pdf_img} alt="" />
                                                        </a>
                                                    </div>
                                                    <div
                                                        className="col-span-1"
                                                        style={{ color: "rgb(8, 101, 182)" }}
                                                        onClick={() => {
                                                            handleOpen();
                                                            setDocId(row.id);
                                                            setUrl(row.document_name);
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            fontSize="medium"
                                                            style={{ color: "#157EFB", height: 40 }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="bg-[#e5e5e5] text-[#157EFB]"
                                                    id="PDF_filename_div"
                                                >
                                                    <span className="font-bold">File name: </span>
                                                    <p className="px-3 pb-2">
                                                        {row.document_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
            // closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
            >
                {/*<Fade in={open}>*/}
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        {docId !== ""
                            ? "Want to delete the document?? Please confirm"
                            : "Want to save the document?? Please confirm"}
                    </Typography>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        <p className="text-base">
                            Document -{" "}
                            <span className="text-gray-400 line-clamp-2">
                                {url.slice(0, 40)}
                            </span>
                        </p>
                    </Typography>
                    <Typography className="mt-2 space-x-3">
                        <Button
                            className={`${docId !== "" ? "opacity-40" : "hover:bg-red-300"
                                } duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full`}
                            disabled={docId !== "" ? true : false}
                            onClick={other === true ? UploadOtherDocument : UploadDocument}
                            style={{ background: "#0865B6" }}
                        >
                            Save
                        </Button>
                        <Button
                            className={`${docId === "" ? "opacity-40" : "hover:bg-red-300"
                                } duration-300 text-white px-3 py-2 text-sm md:px-5 md:py-3 sm:text-sm md:text-base rounded-full`}
                            disabled={docId === "" ? true : false}
                            onClick={DeleteDocument}
                            style={{ background: "red" }}
                        >
                            Delete
                        </Button>
                        <Button
                            className="text-white px-3 py-2"
                            style={{ backgroundColor: "tomato" }}
                            onClick={() => window.location.reload()}>
                            Cancel
                        </Button>
                    </Typography>
                </Box>
                {/*</Fade>*/}
            </Modal>
        </div>
    );
};

export default Document;
