import React, {useEffect} from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {Route, Routes} from "react-router";
import Upcoming from "./Upcoming";
import Previous from "./Previous";
import {useNavigate, Navigate} from "react-router-dom";


import Cookies from "universal-cookie";

const cookies = new Cookies();

const ScheduleMeets = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.get("token")) {

        } else {
            navigate("/")
        }
    }, []);

    return (
        <div className="bg-default-background h-screen overflow-y-scroll">
            <Header/>
            <Sidebar/>

            <div className="items-center justify-start text-neutral-400 flex bg-white py-2 m-2 rounded-md z-10">
                <span
                    id="Message From"
                    className={`font-lighter text-black px-4 py-2 rounded-md duration-300`}
                >
                  Scheduled Meetings:
                </span>
                <a
                    id="upcoming"
                    className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base
                            m-1 py-1 px-2.5
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                    ${window.location.pathname === "/schedule_meets/upcoming" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                    onClick={(e) => navigate("upcoming")}
                >
                    Upcoming
                </a>
                <a
                    id="previous"
                    className={`hover:bg-[#F1F1F1] hover:text-sky-700 rounded-[5px] font-bold text-base
                            m-1 py-1 px-2.5
                            md:px-8 md:text-xl md:py-2 md:1.5
                            lg:px-10 lg:text-xl lg:py-2 lg:m-1.5
                    ${window.location.pathname === "/schedule_meets/previous" ? "bg-[#F1F1F1] text-sky-700" : ""}`}
                    onClick={(e) => navigate("previous")}
                >
                    Previous
                </a>
            </div>

            <Routes>
                <Route path="upcoming" element={<Upcoming/>}/>
                <Route path="previous" element={<Previous/>}/>
            </Routes>
        </div>
    )

}

export default ScheduleMeets;