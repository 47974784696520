import EditIcon from "@mui/icons-material/Edit";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../../../components/consts";
import pdf_img from "../Resume/Pdf.png";


import Cookies from "universal-cookie";
const cookies = new Cookies();

const Certificates = () => {
    const [timeline, setTimeline] = useState([]);
    const [id, setId] = useState("")

    let navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${base_url}/profile/certificate`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {

                setTimeline(res.data);
            })
            .catch((err) => {

            });
    }, []);

    return (
        <div className="px-0 md:px-10 lg:px-16 py-3 cursor-default">
            <div className="card border-none rounded-lg bg-white mb-3 h-[70vh] overflow-auto">
                <div className="card-body">
                    <div className="card-text py-3">
                        <div className="grid grid-cols-1 space-y-3 md:space-y-0 md:grid-cols-2 mb-2">
                            <div>
                                <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">
                                    Certificate
                                </p>
                            </div>
                            <div className="text-left md:text-right">
                                <Button
                                    className="text-base px-3 py-1"
                                    style={{
                                        background: "#F1F1F1",
                                        color: "#0865B6",
                                        borderRadius: "0.5rem",
                                        fontWeight: "bold"
                                    }}
                                >
                                    <Link to="add">+ Add More</Link>
                                </Button>
                            </div>
                        </div>
                        <div className="max-w-[60vw]">
                            {timeline.map((row) => (
                                <Timeline position="right" key={row.id}>
                                    <TimelineItem className="before:hidden">
                                        {/*<TimelineOppositeContent*/}
                                        {/*    sx={{m: "auto 0"}}*/}
                                        {/*    align="right"*/}
                                        {/*    variant="body2"*/}
                                        {/*    color="text.secondary"*/}
                                        {/*>*/}
                                        {/*    <Typography>*/}
                                        {/*        <span className="text-gray-400 text-xs md:text-base font-bold">*/}
                                        {/*            {row.start_year*/}
                                        {/*                ? row.start_year?.toString()?.slice(0, 4)*/}
                                        {/*                : "not available"}{" "}*/}
                                        {/*            -*/}
                                        {/*        </span>*/}
                                        {/*    </Typography>*/}
                                        {/*    <Typography>*/}
                                        {/*        <span className="text-gray-400 text-xs md:text-base font-bold">*/}
                                        {/*            {row.end_year === "Invalid date"*/}
                                        {/*                ? "On-Going"*/}
                                        {/*                : "not available" || row.end_year*/}
                                        {/*                    ? row.end_year?.toString()?.slice(0, 4)*/}
                                        {/*                    : "not available"}*/}
                                        {/*        </span>*/}
                                        {/*    </Typography>*/}
                                        {/*</TimelineOppositeContent>*/}
                                        <TimelineSeparator className="px-3 py-1">
                                            <TimelineDot sx={{ background: "#0865B6" }} />
                                            <TimelineConnector sx={{ background: "#0865B6" }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                className="flex space-x-2"
                                            >
                                                <span className="text-black text-lg md:text-xl font-[600]">
                                                    {row.degree_name ? row.degree_name : "not available"}
                                                </span>
                                                <div
                                                    className="bg-blue-300 rounded-full w-8 h-8 flex flex-col items-center justify-center
                                                    hover:cursor-pointer hover:bg-blue-400 duration-300"
                                                    id={row.id}
                                                    onClick={(e) => setId(e.currentTarget.id)}
                                                >
                                                    {/*<Link to="update">*/}
                                                    <EditIcon
                                                        onClick={() => {
                                                            localStorage.setItem("CertificateID", row.id);
                                                            navigate("update")
                                                            // window.location.reload();
                                                        }}
                                                        style={{ height: 14, color: "black" }} />
                                                    {/*</Link>*/}
                                                </div>
                                            </Typography>
                                            <Typography variant="subtitle1" className="font-bold">
                                                <p className="text-black font-[600] text-sm md:text-base">
                                                    {" "}
                                                    <span>
                                                        {row.university_name
                                                            ? row.university_name
                                                            : "not available"}
                                                    </span>

                                                    <br />
                                                    <span className="text-gray-400 text-xs md:text-sm font-bold">
                                                        {" "}
                                                        {row.city ? row.city : "not available"},{" "}
                                                        {row.country ? row.country : "not available"}
                                                    </span>{" "}
                                                    <br />
                                                    <span className="text-gray-400 text-xs md:text-base font-bold">
                                                        {row.start_year
                                                            ? row.start_year?.toString()?.slice(0, 4)
                                                            : "not available"}{" "}
                                                        -{" "}
                                                        {row.end_year === "Invalid date"
                                                            ? "On-Going"
                                                            : "not available" || row.end_year
                                                                ? row.end_year?.toString()?.slice(0, 4)
                                                                : "not available"}
                                                    </span>
                                                </p>
                                            </Typography>
                                            <div
                                                className="card border-none mt-2 w-36 md:w-72 rounded-lg"
                                                style={{ backgroundColor: "#F3F3F3" }}
                                                align="center"
                                            >
                                                <div className="cardbody">
                                                    <div className="cardtext">
                                                        <div className="">
                                                            <div>
                                                                <a
                                                                    href={row.certificate}
                                                                    download
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <img className="p-2 w-20 md:w-28" src={pdf_img}
                                                                        alt="" />
                                                                </a>
                                                            </div>
                                                            {/* <div
                                                                className="col"
                                                                style={{ color: "rgb(8, 101, 182)" }}
                                                              >
                                                                <DeleteIcon
                                                                  className="m-2 hover:text-white"
                                                                  fontSize="small"
                                                                  style={{ color: "#157EFB", height: 40}}
                                                                />
                                                              </div> */}
                                                        </div>
                                                        <div
                                                            className="bg-[#e5e5e5] rounded-b-lg text-[#157EFB] text-xs md:text-base p-2"
                                                            id="PDF_filename_div"
                                                        >
                                                            <span className="font-semibold">File name: </span>
                                                            <span>{row.certificate_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certificates;
