import { ChevronLeft, PresentToAll } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventNoteIcon from '@mui/icons-material/EventNote';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import { Drawer, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// const cookies = new Cookies();

const Sidebar = ({ open, onClose }) => {


    const navigate = useNavigate();
    const path = window.location.pathname.split("/")[1]

    // const Logout = () => {

    //     const confirm = window.confirm("confirm logout")
    //     if (!confirm) return
    //     cookies.remove("token")
    //     navigate("/login")
    // }

    return (
        <Drawer
            title={<div className="text-[#0865B6]">HR Intelligence</div>}
            placement={"left"}
            // closable={false}
            onClose={onClose}
            open={open}
            width={280}
            disableAutoFocus
            extra={
                //   <Space>
                <IconButton onClick={onClose}>
                    <ArrowBackIosNewIcon className="arrow_icon" />
                </IconButton>
                //   </Space>
            }
        >
            <div title="" className='sidebar_main  '>

                <div
                    className={`
                 sidebar_parent_div  pl-5 pr-5 pt-2`}
                >

                    <div className='sidebar_options_div z-40 text-base flex flex-col gap-2 h-full'>
                        <div className="text-[#0865B6] p-2 font-semibold justify-between border-b border-gray-300 items-center  flex ">HR Intelligence
                            <IconButton onClick={onClose}>
                                <ChevronLeft sx={{ fontSize: 30 }} />
                            </IconButton>
                        </div>


                        <div
                            onClick={() => navigate("/dashboard")}
                            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${path === "dashboard" ? "bg-neutral-100 text-sky-700" : ""}`}
                        >
                            <p className="m-1">
                                <DashboardIcon className="mr-5" />
                                Dashboard
                            </p>
                        </div>

                        <div
                            onClick={() => navigate("/applications/jobs")}
                            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                        ${path === "applications" ? "bg-neutral-100 text-sky-700" : ""}`}
                        >
                            <p className="m-1">
                                <InsertDriveFileIcon className="mr-5" />
                                Jobs
                            </p>
                        </div>

                        <div
                            onClick={() => navigate("/webinar/upcoming")}
                            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                        ${path === "webinar" ? "bg-neutral-100 text-sky-700" : ""}`}
                        >
                            <p className="m-1">
                                <PresentToAll className="mr-5" />
                                Webinar
                            </p>
                        </div>


                        <div
                            onClick={() => navigate("/calendar")}
                            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${path === "calendar" ? "bg-neutral-100 text-sky-700" : ""}`}
                        >
                            <p className="m-1">
                                <EventNoteIcon className="mr-5" />
                                Calendar
                            </p>
                        </div>

                        <div
                            onClick={() => navigate("/search")}
                            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                        ${path === "search" ? "bg-neutral-100 text-sky-700" : ""}`}
                        >
                            <p className="m-1">
                                <SearchIcon className="mr-5" />
                                Search
                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default Sidebar;