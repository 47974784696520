import React, {useEffect, useState} from "react";
import {Button, Card, CardContent} from "@mui/material";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';

import Cookies from "universal-cookie";
import { base_url } from "../../../../components/consts";

const cookies = new Cookies();

const Education = () => {

    const navigate = useNavigate();

    const [educationData, setEducationData] = useState([]);
    

    const getData = () => {
        axios
            .get(
                `${base_url}/profile/education-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setEducationData(res.data);
            })
            .catch((err) => {
                
            })
    }


    useEffect(() => {
        
        getData();
    }, []);

    return (
        <div>
            <Card className="my-3" elevation={0} sx={{borderRadius: "8px"}}>
                <CardContent className="m-2">
                    <div className="flex flex-row">
                        <div className="flex-grow">
                            <p className="text-sky-700 text-xl px-2 md:px-4 md:text-2xl lg:text-2xl font-bold">
                                Education
                            </p>
                        </div>
                        <div className="h-10">
                            {educationData.length > 0 ?
                                <Button
                                    disableElevation
                                    endIcon={<EditIcon/>}
                                    variant="contained"
                                    sx={{
                                        background: "#F1F1F1",
                                        color: "#0865B6",
                                        borderRadius: "8px",
                                        ":hover": {background: "#E4E4E4"}
                                    }}
                                    onClick={() => navigate("update")}
                                >
                                    Edit
                                </Button> : <></>}
                        </div>
                    </div>


                    {educationData.length > 0 ? <div>
                            {educationData.map((data) => (
                                <div className="text-xl space-y-5 mx-5 mb-4" key={data}>

                                    <div className="text-2xl col-span-2 pt-6">
                                        <p className="text-neutral-900 break-all">
                                            {data.title}
                                        </p>
                                    </div>

                                    <div className="flex flex-col space-y-5 md:space-y-0 md:gap-5 md:flex-row">
                                        <div className="w-1/2">
                                            <p className="text-neutral-400">
                                                Course
                                            </p>
                                            <p className="text-neutral-900">
                                                {data.course}
                                            </p>
                                        </div>
                                        <div className="w-1/2">
                                            <p className="text-neutral-400">
                                                Specialization
                                            </p>
                                            <p className="text-neutral-900">
                                                {data.specialization}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex flex-col space-y-5 md:space-y-0 md:gap-5 md:flex-row">
                                        <div className="w-1/2">
                                            <p className="text-neutral-400">
                                            Institute Name
                                            </p>
                                            <p className="text-neutral-900 break-words">
                                                {data.institute_name}
                                            </p>
                                        </div>
                                        <div className="w-1/2">
                                            <p className="text-neutral-400">
                                                Course Type
                                            </p>
                                            <p className="text-neutral-900">
                                                {data.course_type}
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        {data.is_complete === true ? <div>
                                            <p className="text-neutral-400">
                                                Pass Out Year
                                            </p>
                                        </div> : <div>
                                            <p className="text-neutral-400">
                                                Probable Pass Out
                                            </p>
                                        </div>}

                                        <p className="text-neutral-900">
                                            {data.pass_year}
                                        </p>
                                    </div>

                                </div>
                            ))}
                        </div>
                        : <div>
                            <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                    background: "#F1F1F1",
                                    color: "#0865B6",
                                    borderRadius: "8px",
                                    ":hover": {background: "#E4E4E4"}
                                }}
                                onClick={() => navigate("add")}
                            >
                                Add Information
                            </Button>
                        </div>}


                </CardContent>
            </Card>
        </div>
    )
}

export default Education;