import React, {useEffect, useState} from "react";
import axios from "axios";
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Cookies from "universal-cookie";
import {Divider, Slider} from "@mui/material";
import {styled} from "@mui/material/styles";

const cookies = new Cookies();

const Template4 = () => {


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [designation, setDesignation] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [linkedIn, setLinkedIn] = useState("");

    const [personalDetails, setPersonalDetails] = useState([]);
    const [workExperience, setWorkExperience] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [skills, setSkills] = useState([]);
    const [certificates, setCertificates] = useState([]);

    const getPersonalDetails = () => {
        axios
            .get(
                `profile/personal`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setPersonalDetails(res.data);
                setFirstName(res.data[0].first_name);
                setLastName(res.data[0].last_name);
                setDesignation(res.data[0].designation);
                setProfilePic(res.data[0].profile_pic);
                setLinkedIn(res.data[0].linkedin_id);
            })
            .catch((err) => {
                
            })
    }

    const getEducationData = () => {
        axios
            .get(
                `profile/education-list`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                
                setEducationData(res.data);
            })
            .catch((err) => {
                
            })
    }

    const getWorkExperience = () => {
        axios
            .get(
                `profile/workExperience`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setWorkExperience(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getSkills = () => {
        axios
            .get(
                "/profile/skills",
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setSkills(res.data);
            })
            .catch((err) => {
                
            });
    }

    const getCertificate = () => {
        axios
            .get(
                `profile/certificate`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                })
            .then((res) => {
                
                setCertificates(res.data);
            })
            .catch((err) => {
                
            });
    }


    const SkillSlider = styled(Slider)({
        color: "#303845",
        height: 20,
        border: "#303845",
        "& .MuiSlider-track": {
            backgroundColor: "#303845",
        },
        "& .MuiSlider-thumb": {
            display: "none"
        }
    });

    useEffect(() => {
        getPersonalDetails();
        getEducationData();
        getWorkExperience();
        getSkills();
        getCertificate();
    }, []);

    return (
        <div>
            {personalDetails.map((personal) => (
                <div key={personal} className="bg-[#303845] text-neutral-400 p-5 space-y-3">
                    <div className="text-white">
                        <p className="text-2xl">
                            {firstName} {lastName}
                        </p>
                        <p>
                            {designation}
                        </p>
                    </div>
                    <div className="flex space-x-4">
                        <p>
                            <MailIcon fontSize={"small"} className="mr-4"/>
                            {personal.email}
                        </p>
                        <p>
                            <LocalPhoneIcon fontSize={"small"} className="mr-4"/>
                            {personal.mobile_no}
                        </p>
                    </div>
                    <p className="break-all">
                        <LocationOnIcon fontSize={"small"} className="mr-4"/>
                        {personal.present_address}
                    </p>
                </div>
            ))}

            <div className="flex flex-row my-4">
                <div className="w-4/6 px-4 space-y-5">
                    <div>
                        <p className="text-xl font-bold pb-3">
                            Profile
                        </p>
                        <p className="text-xs text-justify break-words">
                            Your profile is one of the most important parts of your resume.
                            this section should give a concise summary of your career to date (5 sentences max).
                            this will be one of the first section your future employee/recruiter will see, and you
                            don't want to be the only one!
                            Your profile needs to capture their attention and get them to read your whole CV.
                            One final tip, it is usually easy to write this part last.

                        </p>
                    </div>
                    <Divider variant="middle"/>
                    <div>
                        <p className="text-xl font-bold pb-3">
                            Education
                        </p>
                        {educationData.map((eduData) => (
                            <div key={eduData}>
                                <p>
                                    {eduData.course}
                                </p>
                                <p>
                                    {eduData.university_name}, {eduData.pass_year}
                                </p>
                            </div>
                        ))}
                    </div>
                    <Divider variant="middle"/>
                    <div>
                        <p className="text-xl font-bold pb-3">
                            Employment
                        </p>
                        {workExperience.map((workEx) => (
                            <div key={workEx.id} className="text-sm py-3">
                                <div className="flex">
                                    <div className="flex-grow">
                                        <p>
                                            {workEx.position}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {workEx.start_date} - {workEx.job_status === "On-Going" ? workEx.job_status : workEx.end_date}
                                        </p>
                                    </div>
                                </div>
                                <p>
                                    {workEx.company_name}, {workEx.city}
                                </p>
                                <p className="break-all">
                                    {workEx.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem style={{height: 'auto'}}/>
                <div className="w-2/6 px-4 space-y-5">
                    <div>
                        <p className="text-xl font-bold pb-3">
                            Personal Details
                        </p>
                        <p>
                            LinkedIn
                        </p>
                        <p className="text-sm break-all">
                            {linkedIn}
                        </p>
                    </div>

                    <Divider variant="middle"/>

                    <div>
                        <p className="text-xl font-bold pb-3">
                            Skills
                        </p>
                        {skills.map((skillData) => (
                            <div key={skillData}>
                                <p className="text-sm text-[#98989C] break-all">{skillData.skills}</p>
                                <SkillSlider value={skillData.rating * 10}/>
                            </div>
                        ))}
                    </div>

                    <Divider variant="middle"/>
                    <div>
                        <p className="text-xl font-bold pb-3">
                            Certificates
                        </p>
                        {certificates.map((certiData) => (
                            <div key={certiData} className="text-sm py-3">
                                <p>
                                    {certiData.degree_name}
                                </p>
                                <p>
                                    {certiData.university_name}, {certiData.start_tear} - {certiData.end_year}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Template4;